import { debouncedCheckUsername } from "@/helper/functions/auth/checkUsernameAvailable";
import { fieldTypeBuilder } from "@/helper/lib/utils";
import { usernameField } from "@/helper/static/Onboarding/values";
import { useUsernameStore } from "@/helper/store/usernameStore";
import { useField } from "formik";
import { type FC, useEffect } from "react";
import { InputComponent } from "./InputComponent";
import { InputLabelComponent } from "./InputLabel";

export function UsernameInputComponent() {
  const FieldName = fieldTypeBuilder("User", usernameField);
  const FieldID = "Username";
  const [field, meta] = useField(FieldName);
  // TODO use Zustand to check the value and prevent it from going to review if the value is in use.
  // TODO when typing gibberish words, it somehow sends a request and returns an error.
  // This needs to be prevented.
  const { usernameError, setUsernameError, readyToCallAPI } =
    useUsernameStore();
  // Trigger debounced check if Formik meta.error changes and username doesn't contain an error
  useEffect(() => {
    if (readyToCallAPI && field.value) {
      debouncedCheckUsername(field.value, setUsernameError);
    }
  }, [field.value, readyToCallAPI, setUsernameError]);

  return (
    <div className="w-full space-y-2">
      <InputLabelComponent
        LabelText="Kullanıcı Adı"
        For={FieldID}
        FieldName={FieldName}
        classes={usernameError ? "text-red-500" : ""}
      />
      <InputComponent
        Name={FieldName}
        FieldId={FieldID}
        Placeholder="Username"
        InputType="text"
        classes={usernameError ? "border-destructive" : ""}
      />
      <UsernameError error={meta.error} usernameError={usernameError} />
    </div>
  );
}

interface UsernameErrorProps {
  error: string | undefined;
  usernameError: string | undefined;
}

const UsernameError: FC<UsernameErrorProps> = ({ error, usernameError }) => {
  return error || usernameError ? (
    /* Show an error message from Formik or username check */
    <div className="select-none text-xs font-semibold italic text-red-500">
      {error || usernameError}
    </div>
  ) : null;
};
