import ThemeToggle from "@/components/ui/mode-toggle";
import type { FC } from "react";
import { DestructedFormikField } from "./DestructedField";

interface Props {
  fieldName?: string;
  buttonClasses?: string;
  size?: "sm" | "lg" | "icon" | "default" | null | undefined;
  align?: "start" | "end" | "center" | undefined;
}

export const ThemeDropdownComponent: FC<Props> = ({
  fieldName,
  buttonClasses,
  size,
  align,
}) => {
  const name = "theme";
  return (
    <DestructedFormikField FieldName={fieldName ?? name}>
      {({ field, form }) => {
        return (
          <ThemeToggle
            customFunction={form.setFieldValue}
            fieldName={field.name}
            buttonClasses={buttonClasses}
            size={size}
            dropdownMenuAlignment={align}
          />
        );
      }}
    </DestructedFormikField>
  );
};
