import { InputError } from "@/components/Forms/Inputs/InputError";
import { InputLabelComponent } from "@/components/Forms/Inputs/InputLabel";
import { ThemeDropdownComponent } from "@/components/Forms/Inputs/ThemeDropdown";
import CountryDropdown from "@/components/ui/country-dropdown";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { SettingsSectionValidationSchema } from "@/helper/Schemas/OnboardingSchemas";
import { useStepper } from "@/helper/hooks/useStepper";
import { cn, fieldTypeBuilder } from "@/helper/lib/utils";
import { UserSettingsArray } from "@/helper/static/Onboarding/arrays";
import {
  UserSettings,
  energyUnitField,
  heightUnitField,
  languageField,
  mealCountryField,
  themeField,
  waterUnitField,
  weightUnitField,
} from "@/helper/static/Onboarding/values";
import { useBoardingStore } from "@/helper/store/boardingStore";
import { useField } from "formik";
import { Info } from "lucide-react";
import { MultiStepComponentFormWrapper } from "../MultiStepFormWrapper";
import { BoardingCombobox, BoardingRow } from "../UIElements";

export function OnboardingUserSettingsSection() {
  const {
    boarding,
    setBoardingUserSettings,
    nextStep,
    stepNumber,
    setStepValidity,
  } = useBoardingStore();

  const initValues = {
    UserSettings: {
      [mealCountryField]: boarding.UserSettings.preferred_meal_country,
      [languageField]: boarding.UserSettings.language,
      [weightUnitField]: boarding.UserSettings.weight_unit,
      [heightUnitField]: boarding.UserSettings.height_unit,
      [waterUnitField]: boarding.UserSettings.water_unit,
      [energyUnitField]: boarding.UserSettings.energy_unit,
      [themeField]: boarding.UserSettings.theme,
    },
  };
  const { nextStep: stepperNextFunction } = useStepper();

  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  const onSubmitHandler = (values: any) => {
    setBoardingUserSettings(values.UserSettings);

    setStepValidity(stepNumber, true);
    nextStep();
    stepperNextFunction();
  };

  return (
    <MultiStepComponentFormWrapper
      initValues={initValues}
      onSubmit={onSubmitHandler}
      validationSchema={SettingsSectionValidationSchema}
    >
      <OnboardingThemeHandler />
      <BoardingBoxes />
    </MultiStepComponentFormWrapper>
  );
}

// TODO bunların ayrı dosyası olacak

export const BoardingBoxes = () => {
  const countryField = fieldTypeBuilder(UserSettings, mealCountryField);
  const [, meta, helpers] = useField<string>(countryField);

  return (
    <div className="flex flex-wrap items-center justify-center gap-2 max-sm:flex-col">
      {UserSettingsArray.map((setting) => (
        <BoardingCombobox
          key={setting.id}
          FieldName={setting.FieldName}
          Options={setting.Options}
          DefaultLabel={setting.DefaultLabel}
          EmptyText={setting.EmptyText}
          PlaceholderText={setting.PlaceholderText}
          buttonClassForWidth={"w-[300px]"}
          TooltipContent={setting.TooltipContent}
        />
      ))}
      <div className={"flex flex-col gap-2 max-sm:w-full"}>
        <InputLabelComponent
          LabelText="Yemeklerinizin ülkesini seçin"
          FieldName={countryField}
          tooltip
          Content="Seçtiğiniz ülke, yemeklerin kalori ve besin değerlerinin belirlenmesinde baz alınacaktır. Unutmayın, aynı yemeğin besin değerleri farklı ülkelerde değişiklik gösterebilir. Bu seçim, size sunulacak yemek önerilerinde kullanılacaktır."
        />

        <CountryDropdown
          widthClass={"w-[300px]"}
          customOnChange={helpers.setValue}
          buttonClasses={cn(meta.error && meta.touched && "border-destructive")}
          isMeal
        />
        <InputError Name={countryField} />
      </div>
    </div>
  );
};

export function OnboardingThemeHandler() {
  const name = fieldTypeBuilder(UserSettings, themeField);
  return (
    <BoardingRow col extraClasses="space-y-2">
      <InputLabelComponent
        LabelText="Temanızı seçiniz"
        FieldName={name}
        tooltip
        Content="Dark ve Light temalar arasından seçim yapabilirsiniz. System seçeneği cihazınızın temasına göre otomatik olarak seçim yapar."
        Label={<Info className="h-4 w-4" />}
      />
      <ThemeDropdownComponent
        fieldName={name}
        size={"lg"}
        buttonClasses="w-full"
        align="center"
      />
      <InputError Name={name} />
    </BoardingRow>
  );
}
