import type {
  Boarding,
  BoardingUser,
  BoardingUserAllergy,
  BoardingUserHealthProfiles,
  BoardingUserSettings,
  GoalProfileChooser,
} from "@/helper/types/Boarding";
import type React from "react";
import { create } from "zustand";
import { defaultBirthDate } from "../lib/utils";

interface MultiStepForm {
  number: number;
  title: string;
  description: string;
  content: React.JSX.Element;
}

interface BoardingStore {
  boarding: Boarding;
  setBoardingUser: (update: Partial<BoardingUser>) => void;
  setBoardingUserHealthProfiles: (
    update: Partial<BoardingUserHealthProfiles>,
  ) => void;
  setBoardingUserAllergy: (update: Partial<BoardingUserAllergy>) => void;
  setBoardingUserSettings: (update: Partial<BoardingUserSettings>) => void;
  reviewMode: boolean;
  setReviewMode: (value: boolean) => void;
  GoalProfileChooser: GoalProfileChooser;
  stepNumber: number;
  setStepNumber: (stepNumber: number) => void;
  previousStep: () => void;
  nextStep: () => void;
  MultiStepForm: MultiStepForm[];
  setMultiStepForm: (update: MultiStepForm[]) => void;
  stepsValidity: { [key: number]: boolean };
  setStepValidity: (stepNumber: number, isValid: boolean) => void;
}

type HealthProfileFields = keyof Boarding["UserHealthProfiles"];
type AllergyFields = keyof Boarding["UserAllergy"];
type UserFields = keyof Boarding["User"];
type SettingsFields = keyof Boarding["UserSettings"];

export type { AllergyFields, HealthProfileFields, SettingsFields, UserFields };

export const useBoardingStore = create<BoardingStore>((set) => ({
  boarding: {
    User: {
      username: "",
      email: "",
      provider: "",
      socialID: "",
      firstName: "",
      lastName: "",
      isActive: false,
      providerProfilePicture: "",
    },
    UserHealthProfiles: {
      birthDate: defaultBirthDate,
      gender: "male",
      height: 0,
      weight: 0,
      activityLevel: "",
      healthGoal: "",
      smokingStatus: false,
      alcoholConsumption: false,
      existingConditions: [],
      customCalorieGoal: 2000,
      goalStartDate: new Date(),
      goalDailyWaterIntake: 2000,
    },
    UserAllergy: {
      allergies: [],
    },
    UserSettings: {
      preferred_meal_country: "",
      theme: "system",
      language: "",
      weight_unit: "",
      height_unit: "",
      water_unit: "",
      energy_unit: "",
    },
  },
  setBoardingUser: (update) =>
    set((state) => ({
      boarding: {
        ...state.boarding,
        User: { ...state.boarding.User, ...update },
      },
    })),
  setBoardingUserHealthProfiles: (update) =>
    set((state) => ({
      boarding: {
        ...state.boarding,
        UserHealthProfiles: { ...state.boarding.UserHealthProfiles, ...update },
      },
    })),
  setBoardingUserAllergy: (update) =>
    set((state) => ({
      boarding: {
        ...state.boarding,
        UserAllergy: { ...state.boarding.UserAllergy, ...update },
      },
    })),
  setBoardingUserSettings: (update) =>
    set((state) => ({
      boarding: {
        ...state.boarding,
        UserSettings: { ...state.boarding.UserSettings, ...update },
      },
    })),
  reviewMode: false,
  setReviewMode: (value) => set(() => ({ reviewMode: value })),
  GoalProfileChooser: {
    profileChooser: false,
  },
  stepNumber: -1,
  setStepNumber: (stepNumber) => set(() => ({ stepNumber })),
  previousStep: () => set((state) => ({ stepNumber: state.stepNumber - 1 })),
  nextStep: () => set((state) => ({ stepNumber: state.stepNumber + 1 })),
  MultiStepForm: [],
  setMultiStepForm: (update) => set(() => ({ MultiStepForm: update })),
  stepsValidity: {},
  setStepValidity: (stepNumber, isValid) =>
    set((state) => ({
      stepsValidity: {
        ...state.stepsValidity,
        [stepNumber]: isValid,
      },
    })),
}));
