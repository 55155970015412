import { fieldTypeBuilder } from "@/helper/lib/utils";
import type { SettingsFields } from "@/helper/store/boardingStore";
import {
  UserSettings,
  energyUnitField,
  heightUnitField,
  languageField,
  waterUnitField,
  weightUnitField,
} from "./values";

export const activityLevels = [
  { value: "minimal", label: "Minimal" },
  { value: "low", label: "Low" },
  { value: "moderate", label: "Moderate" },
  { value: "high", label: "High" },
  { value: "very high", label: "Very High" },
];

export const languages = [
  { value: "tr", label: "Türkçe" },
  { value: "en", label: "English" },
];

export const weightUnits = [
  { value: "g", label: "Gram" },
  { value: "kg", label: "Kilogram" },
  { value: "lb", label: "Pound" },
  { value: "oz", label: "Ounce" },
];

export const servingSizeUnits = [
  { value: "g", label: "Gram" },
  { value: "mg", label: "Miligram" },
  { value: "oz", label: "Ounce" },
  { value: "cup", label: "Cup" },
  { value: "tbsp", label: "Tablespoon" },
  { value: "tsp", label: "Teaspoon" },
];

export const heightUnits = [
  { value: "cm", label: "Centimeter" },
  { value: "m", label: "Meter" },
  { value: "in", label: "Inch" },
  { value: "ft", label: "Feet" },
];

export const waterUnits = [
  { value: "ml", label: "Mililitre" },
  { value: "liters", label: "Litre" },
  { value: "oz", label: "Fluid Ounce" },
  { value: "gal", label: "Gallon" },
];

export const energyUnits = [
  { value: "kcal", label: "Kilocalorie" },
  { value: "joules", label: "Joules" },
  { value: "BTU", label: "British Thermal Unit" },
];

export const goals = [
  { value: "weight_loss", label: "Weight Loss" },
  { value: "muscle_gain", label: "Muscle Gain" },
  { value: "low_carb", label: "Low Carb" },
  { value: "keto", label: "Keto" },
  { value: "high_carb", label: "High Carb" },
  { value: "balanced", label: "Balanced" },
  { value: "heart_health", label: "Heart Health" },
  { value: "immune_boost", label: "Immune Boost" },
  { value: "bone_health", label: "Bone Health" },
];

export const themes = [
  { value: "light", label: "Light" },
  { value: "dark", label: "Dark" },
  { value: "system", label: "System" },
];

const FieldNameBuilder = (field: SettingsFields) =>
  fieldTypeBuilder(UserSettings, field);

export const UserSettingsArray = [
  {
    id: 1,
    DefaultLabel: "Dil Seçiniz",
    EmptyText: "Dil bulunamadı.",
    PlaceholderText: "Dil arayın...",
    FieldName: FieldNameBuilder(languageField),
    Options: languages,
    TooltipContent:
      "Uygulama dilini buradan seçebilirsiniz. Bu seçim, uygulama genelinde kullanılacak dili belirler.",
  },
  {
    id: 2,
    DefaultLabel: "Kilo Birimi Seçiniz",
    EmptyText: "Kilo birimi bulunamadı.",
    PlaceholderText: "Kilo birimi arayın...",
    FieldName: FieldNameBuilder(weightUnitField),
    Options: weightUnits,
    TooltipContent:
      "Kullanmak istediğiniz kilo birimini buradan seçebilirsiniz. Tercihiniz, uygulama genelinde bu birimin kullanılmasını sağlar.",
  },
  {
    id: 3,
    DefaultLabel: "Yükseklik Birimi Seçiniz",
    EmptyText: "Yükseklik birimi bulunamadı.",
    PlaceholderText: "Yükseklik birimi arayın...",
    FieldName: FieldNameBuilder(heightUnitField),
    Options: heightUnits,
    TooltipContent:
      "Yükseklik birimi seçiminizi buradan yapabilirsiniz. Bu seçim, uygulama genelinde kullanılacak yükseklik birimini belirler.",
  },
  {
    id: 4,
    DefaultLabel: "Su Birimi Seçiniz",
    EmptyText: "Su birimi bulunamadı.",
    PlaceholderText: "Su birimi arayın...",
    FieldName: FieldNameBuilder(waterUnitField),
    Options: waterUnits,
    TooltipContent:
      "Su birimi seçiminizi buradan yapabilirsiniz. Bu seçim, uygulamada kullanılacak su birimini belirler.",
  },
  {
    id: 5,
    DefaultLabel: "Enerji Birimi Seçiniz",
    EmptyText: "Enerji birimi bulunamadı.",
    PlaceholderText: "Enerji birimi arayın...",
    FieldName: FieldNameBuilder(energyUnitField),
    Options: energyUnits,
    TooltipContent:
      "Enerji birimini buradan seçebilirsiniz. Bu seçim, uygulamada kullanılacak enerji birimini belirler.",
  },
];
