import { APIUrlBuilder } from "@/helper/functions/ApiUrlbuilder";
import { getCustomClaims } from "@/helper/functions/auth/SignMethods/RoleCheckedEmailPass";
import { auth } from "@/helper/functions/auth/firebase";
import { fetchData } from "@/helper/functions/fetch";
import { queryClient } from "@/helper/lib/react-query";
import { useAuthStore } from "@/helper/store/authStore";
import * as Sentry from "@sentry/react";
import type { LoaderFunction } from "react-router-dom";
import { redirect } from "react-router-dom";

const AUTH_ROUTES = ["/login", "/register"];

export const rootLoader: LoaderFunction = async ({ request }) => {
  const url = new URL(request.url);
  const isAuthRoute = AUTH_ROUTES.includes(url.pathname);
  const isOnboarding = url.pathname === "/onboarding";

  try {
    // Wait for auth state to be initialized (whether user is null or not)
    await new Promise((resolve) => {
      const unsubscribe = auth.onAuthStateChanged((user) => {
        unsubscribe(); // Always unsubscribe after first auth state change
        resolve(user); // Resolve with user (null or authenticated)
      });
    });

    const currentUser = auth.currentUser;
    const store = useAuthStore.getState();
    const { isRegistered, user } = store;

    // Handle auth routes protection
    if ((currentUser || user) && isAuthRoute) {
      return redirect("/");
    }

    // Handle onboarding protection
    if (isOnboarding && isRegistered) {
      return redirect("/");
    }

    // Handle non-auth routes protection
    if (!currentUser && !user && !isAuthRoute) {
      return redirect("/login");
    }

    // Handle registration check
    if (!isRegistered && !isAuthRoute && !isOnboarding) {
      return redirect("/onboarding");
    }

    // Proceed with data fetching only if user is authenticated
    if (currentUser) {
      const claimsQuery = await queryClient.fetchQuery({
        queryKey: ["userClaims"],
        queryFn: getCustomClaims,
      });

      if (!claimsQuery?.role) {
        return redirect("/login");
      }

      const UUID = claimsQuery.UUID;

      const [userData, metrics, healthProfile, settings] = await Promise.all([
        queryClient.fetchQuery({
          queryKey: ["userData", UUID],
          queryFn: () => fetchData(APIUrlBuilder(`/user/${UUID}`)),
        }),
        queryClient.fetchQuery({
          queryKey: ["user-health-metrics", UUID],
          queryFn: () =>
            fetchData(APIUrlBuilder(`/user/${UUID}/health/metrics`)),
        }),
        queryClient.fetchQuery({
          queryKey: ["user-health-Profile", UUID],
          queryFn: () =>
            fetchData(APIUrlBuilder(`/user/${UUID}/health/profile`)),
        }),
        queryClient.fetchQuery({
          queryKey: ["user-settings", UUID],
          queryFn: () => fetchData(APIUrlBuilder(`/user/${UUID}/settings`)),
        }),
      ]);

      return {
        ok: true,
        userData: userData.user,
        metrics: metrics.health_metrics,
        healthProfile: healthProfile.health_profile,
        settings: settings.user_settings,
        claims: claimsQuery,
      };
    }

    // Return consistent structure for non-authenticated routes
    return {
      ok: false,
      userData: null,
      metrics: null,
      healthProfile: null,
      settings: null,
      claims: null,
    };
  } catch (error) {
    console.error("Root loader error:", error);
    Sentry.captureException(error);
    return redirect("/500");
  }
};
