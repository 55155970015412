import { ToastAction } from "@/components/ui/toast";
import { useToast } from "@/components/ui/use-toast";
import { OnboardingSchema } from "@/helper/Schemas/OnboardingSchemas";
import { APIUrlBuilder } from "@/helper/functions/ApiUrlbuilder";
import { convertValuesForBackend } from "@/helper/functions/Onboarding";
import { fetchData } from "@/helper/functions/fetch";
import { capitalizeFirstLetter, cn } from "@/helper/lib/utils";
import { useAuthStore } from "@/helper/store/authStore";
import { useBoardingStore } from "@/helper/store/boardingStore";
import { useUnitStore } from "@/helper/store/unitStore";
import type { Boarding } from "@/helper/types/Boarding";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { MultiStepComponentFormWrapper } from "../MultiStepFormWrapper";

// TODO review kısmını hallet
// şuanda incele butonuna basınca review kısmına geçiyor ve orada bilgileri gösteriyor ama gönder ve geri dön butonu yok.

export function ReviewSection() {
  const { boarding } = useBoardingStore();
  const navigate = useNavigate();
  const { toast } = useToast();

  const { setIsRegistered, setUUID } = useAuthStore();
  const {
    convertedHeight,
    convertedWeight,
    selectedHeightUnit,
    selectedWeightUnit,
  } = useUnitStore();
  // const { selectedWeightUnit, selectedHeightUnit } = useUnitStore();
  const handleFinalSubmit = async (
    values: Boarding,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void },
  ) => {
    setSubmitting(true);
    if (values && boarding) {
      // API endpoint URL
      const endpoint = APIUrlBuilder("/onboarding");

      //? Dönüşüm işlemleri
      const { user, allergies, conditions, health_profiles, settings } =
        convertValuesForBackend(boarding, convertedHeight, convertedWeight);

      try {
        const payload = {
          user,
          allergies,
          conditions,
          health_profiles,
          settings,
        };

        const response = await fetchData(endpoint, "POST", payload);

        if (response.success) {
          toast({
            title: "Kayıt Başarılı!",
            description:
              "Kayıt işlemi başarılı bir şekilde tamamlandı. Ana sayfaya yönlendiriliyorsunuz.",
          });

          setUUID(response.userID as string);
          setIsRegistered(true);

          setTimeout(() => {
            navigate("/"); // Redirect to home after 3 seconds
          }, 3000);
        } else {
          toast({
            variant: "destructive",
            title: "Uh oh! Something went wrong.",
            description: `${response.message} Error:${response.data}`,
            action: <ToastAction altText="Try again">Try again</ToastAction>,
          });
        }
      } catch (error) {
        console.error("Network or server error:", error);
        // Handle network or server errors
        toast({
          variant: "destructive",
          title: "Uh oh! Something went wrong.",
          description: `There was a problem with your request. Error:${error}`,
          action: <ToastAction altText="Try again">Try again</ToastAction>,
        });
      }
    }
    setSubmitting(false);
  };
  return (
    <MultiStepComponentFormWrapper
      initValues={boarding}
      validationSchema={OnboardingSchema}
      onSubmit={(
        values: Boarding,
        { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void },
      ) => handleFinalSubmit(values, { setSubmitting })}
    >
      <dl className="grid grid-cols-1 px-4 sm:grid-cols-3 md:grid-cols-4">
        {/* Review details display */}
        <ReviewSectionField
          label="First name"
          value={capitalizeFirstLetter(boarding.User.firstName)}
        />
        <ReviewSectionField
          label="Last name"
          value={capitalizeFirstLetter(boarding.User.lastName)}
        />
        <ReviewSectionField
          label="Birth Date"
          value={format(boarding.UserHealthProfiles.birthDate, "dd/MM/yyyy")}
        />
        <ReviewSectionField
          label="Gender"
          value={boarding.UserHealthProfiles.gender}
        />
        <ReviewSectionField
          label="Height"
          value={`${boarding.UserHealthProfiles.height} ${selectedHeightUnit}`}
        />
        <ReviewSectionField
          label="Weight"
          value={`${boarding.UserHealthProfiles.weight} ${selectedWeightUnit}`}
        />
        <ReviewSectionField
          label="Activity Level"
          value={boarding.UserHealthProfiles.activityLevel}
        />
        <ReviewSectionField
          label="Allergies"
          value={
            boarding.UserAllergy.allergies.length > 0
              ? boarding.UserAllergy.allergies
              : "Yok"
          }
        />
        <ReviewSectionField
          label="Existing Conditions"
          value={
            boarding.UserHealthProfiles.existingConditions.length > 0
              ? boarding.UserHealthProfiles.existingConditions
              : "Yok"
          }
        />
        <ReviewSectionField
          label="Goal"
          value={boarding.UserHealthProfiles.healthGoal}
        />
        <ReviewSectionField label="Theme" value={boarding.UserSettings.theme} />
        <ReviewSectionField
          label="Language"
          value={boarding.UserSettings.language}
        />
        <ReviewSectionField
          label="Weight Unit(Preferred Unit)"
          value={boarding.UserSettings.weight_unit}
        />
        <ReviewSectionField
          label="Height Unit(Preferred Unit)"
          value={boarding.UserSettings.height_unit}
        />
        <ReviewSectionField
          label="Water Unit"
          value={boarding.UserSettings.water_unit}
        />
        <ReviewSectionField
          label="Energy Unit"
          value={boarding.UserSettings.energy_unit}
        />
        <ReviewSectionField
          label="Smoking Status"
          value={boarding.UserHealthProfiles.smokingStatus}
        />
        <ReviewSectionField
          label="Alcohol Consumption"
          value={boarding.UserHealthProfiles.alcoholConsumption}
        />
        <ReviewSectionField
          label="Meal Country"
          value={boarding.UserSettings.preferred_meal_country}
          LastSoloItem
        />
        <div className="border-t border-accent px-4 sm:col-span-3 sm:px-0 md:col-span-4" />
      </dl>
    </MultiStepComponentFormWrapper>
  );
}

const ReviewSectionField = ({
  label,
  value,
  LastSoloItem,
}: {
  label: string;
  value: string | string[] | boolean | number;
  LastSoloItem?: boolean;
}) => {
  switch (typeof value) {
    case "boolean":
      if (value) {
        value = "Evet";
      } else {
        value = "Hayır";
      }
      break;
    // TODO: Add more cases for different types
    default:
      break;
  }
  return (
    <div
      className={cn(
        LastSoloItem ? "sm:col-span-3 md:col-span-2" : "sm:col-span-1",
        "border-t border-accent px-4 py-6 sm:px-0",
      )}
    >
      <dt className="text-sm font-medium leading-6 text-foreground">{label}</dt>
      <dd className="mt-1 text-sm leading-6 text-foreground/50 sm:mt-2">
        {value || "Yok"}
      </dd>
    </div>
  );
};
