import { formattedDateForGo } from "@/helper/functions/date";
import { capitalizeFirstLetter } from "@/helper/lib/utils";
import type { Boarding } from "@/helper/types/Boarding";

function mapActivityLevel(level: string) {
  switch (level) {
    case "minimal":
      return 1;
    case "low":
      return 2;
    case "moderate":
      return 3;
    case "high":
      return 4;
    case "very high":
      return 5;
    default:
      return 1; // Default to 'minimal' if undefined
  }
}

export function convertValuesForBackend(
  boarding: Boarding,
  convertedHeight: number,
  convertedWeight: number,
) {
  // Simple conversions
  const first_name = capitalizeFirstLetter(
    boarding.User.firstName.trim().toLowerCase(),
  );
  const last_name = capitalizeFirstLetter(
    boarding.User.lastName.trim().toLowerCase(),
  );
  const birthDate = formattedDateForGo(boarding.UserHealthProfiles.birthDate);
  const weight = Number.parseFloat(convertedWeight.toString());
  const height = Number.parseFloat(convertedHeight.toString());
  const activity_level = mapActivityLevel(
    boarding.UserHealthProfiles.activityLevel,
  );

  // Complex fields that might require more processing
  // const goalStartDate = new Date(
  // 	boarding.UserHealthProfiles.goalStartDate,
  // ).toISOString();
  // const customCalorieGoal = Number.parseFloat(
  // 	boarding.UserHealthProfiles.customCalorieGoal.toString(),
  // );
  // const goalDailyWaterIntake = Number.parseFloat(
  // 	boarding.UserHealthProfiles.goalDailyWaterIntake.toString(),
  // );

  // Return an object ready to be JSON-stringified and sent to the backend
  return {
    user: {
      username: boarding.User.username,
      email: boarding.User.email,
      first_name: first_name,
      last_name: last_name,
      provider: boarding.User.provider,
      social_id: boarding.User.socialID,
      is_active: true,
      provider_profile_picture: boarding.User.providerProfilePicture,
    },
    health_profiles: {
      birth_date: birthDate,
      gender: boarding.UserHealthProfiles.gender,
      height: height,
      weight: weight,
      activity_level: activity_level,
      health_goal: boarding.UserHealthProfiles.healthGoal,
      smoking_status: boarding.UserHealthProfiles.smokingStatus,
      alcohol_consumption: boarding.UserHealthProfiles.alcoholConsumption,
      custom_calorie_goal: 0,
      // customCalorieGoal: customCalorieGoal,
      goal_start_date: new Date(),
      goal_daily_water_intake: 0,
      // goalDailyWaterIntake: goalDailyWaterIntake,
    },
    settings: {
      theme: boarding.UserSettings.theme,
      language: boarding.UserSettings.language,
      weight_unit: boarding.UserSettings.weight_unit,
      height_unit: boarding.UserSettings.height_unit,
      water_unit: boarding.UserSettings.water_unit,
      energy_unit: boarding.UserSettings.energy_unit,
      preferred_meal_country: boarding.UserSettings.preferred_meal_country,
    },
    conditions: boarding.UserHealthProfiles.existingConditions,
    allergies: boarding.UserAllergy.allergies,
  };
}
