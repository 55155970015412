import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import clsx from "clsx";
import { motion } from "framer-motion";
import classes from "./WaterCard.module.css";

// TODO bunu daha güzelleştir.
const WaterGlass = ({ dailyWaterGoal = 4396, waterConsumed = 1500 }) => {
  // Calculate the water level as a percentage of the daily goal
  const waterLevel = (waterConsumed / dailyWaterGoal) * 100;
  console.log(waterLevel);

  // Ensure water level is between 0 and 100
  const clampedWaterLevel = Math.min(100, Math.max(0, waterLevel));

  // Translate water level into a scale (1 is full, 0 is empty)
  const waterClipY = 1 - clampedWaterLevel / 100; // value between 0 and 1, inverted for proper Y direction

  // Adjust wave amplitude based on water level
  const waveAmplitude = Math.min(0.1, (clampedWaterLevel / 100) * 0.1); // Adjust wave amplitude

  // Disable animation if waterLevel is 0
  const shouldAnimate = clampedWaterLevel > 0;

  return (
    <Card>
      <CardHeader>
        <CardTitle>Daily Water intake</CardTitle>
      </CardHeader>
      <CardContent>
        <div className={clsx(classes.vase, classes["block-animation"])}>
          {/* The water level is dynamically adjusted based on waterLevel */}
          <motion.div
            className={classes.water}
            initial={{ clipPath: "inset(100% 0 0 0)" }} // Start with water empty
            animate={{
              clipPath: `inset(${100 - clampedWaterLevel}% 0 0 0)`, // Animate water rising or falling
            }}
            transition={{ duration: 1, ease: "easeInOut" }} // Smooth animation
          />
          <svg width="100%" height="100%">
            <title>Water</title>

            {shouldAnimate && (
              <>
                <clipPath id="wave1-clip" clipPathUnits="objectBoundingBox">
                  <motion.path
                    d={`M0,${waterClipY} L0,1 L1,1 L1,${waterClipY} C-1.5,${waterClipY + waveAmplitude} -4,${waterClipY + waveAmplitude / 2} 0,${waterClipY}`}
                    animate={{
                      d: [
                        `M0,${waterClipY} L0,1 L1,1 L1,${waterClipY} C-1.5,${waterClipY + 0.05} -4,${waterClipY + 0.03} 0,${waterClipY}`,
                        `M0,${waterClipY} L0,1 L1,1 L1,${waterClipY} C4,${waterClipY + 0.02} 1.5,${waterClipY + 0.05} 0,${waterClipY}`,
                        `M0,${waterClipY} L0,1 L1,1 L1,${waterClipY} C-1.5,${waterClipY + 0.05} -4,${waterClipY + 0.02} 0,${waterClipY}`,
                      ],
                    }}
                    transition={{
                      repeat: Number.POSITIVE_INFINITY,
                      duration: 5,
                      ease: "easeInOut",
                    }}
                  />
                </clipPath>

                <clipPath id="wave2-clip" clipPathUnits="objectBoundingBox">
                  <motion.path
                    d={`M0,${waterClipY} L0,1 L1,1 L1,${waterClipY} C-1,${waterClipY + waveAmplitude} -5,${waterClipY + waveAmplitude / 2} 0,${waterClipY}`}
                    animate={{
                      d: [
                        `M0,${waterClipY} L0,1 L1,1 L1,${waterClipY} C-1,${waterClipY + 0.05} -5,${waterClipY + 0.03} 0,${waterClipY}`,
                        `M0,${waterClipY} L0,1 L1,1 L1,${waterClipY} C5,${waterClipY + 0.02} 1,${waterClipY + 0.05} 0,${waterClipY}`,
                        `M0,${waterClipY} L0,1 L1,1 L1,${waterClipY} C-1,${waterClipY + 0.05} -5,${waterClipY + 0.02} 0,${waterClipY}`,
                      ],
                    }}
                    transition={{
                      repeat: Number.POSITIVE_INFINITY,
                      duration: 5,
                      ease: "easeInOut",
                    }}
                  />
                </clipPath>
              </>
            )}
          </svg>
        </div>
      </CardContent>
    </Card>
  );
};

export default WaterGlass;

// const WaterGlass = ({ dailyWaterGoal = 4396, waterConsumed = 1500 }) => {
//   // Calculate the water level as a percentage of the daily goal
//   const waterLevel = (waterConsumed / dailyWaterGoal) * 100;
//   console.log(waterLevel);

//   // Ensure water level is between 0 and 100
//   const clampedWaterLevel = Math.min(100, Math.max(0, waterLevel));

//   // Translate water level into a scale (1 is full, 0 is empty)
//   const waterClipY = 1 - clampedWaterLevel / 100; // value between 0 and 1, inverted for proper Y direction

//   // Adjust wave amplitude based on water level
//   const waveAmplitude = Math.min(0.1, (clampedWaterLevel / 100) * 0.1); // Adjust wave amplitude

//   // Disable animation if waterLevel is 0
//   const shouldAnimate = clampedWaterLevel > 0;

//   return (
//     <div className="vase block-animation">
//       {/* The water level is dynamically adjusted based on waterLevel */}
//       <motion.div
//         className="water"
//         initial={{ clipPath: "inset(100% 0 0 0)" }} // Start with water empty
//         animate={{
//           clipPath: `inset(${100 - clampedWaterLevel}% 0 0 0)`, // Animate water rising or falling
//         }}
//         transition={{ duration: 1, ease: "easeInOut" }} // Smooth animation
//       />
//       <svg width="100%" height="100%">
//         <title>Water</title>

//         {shouldAnimate && (
//           <>
//             <clipPath id="wave1-clip" clipPathUnits="objectBoundingBox">
//               <motion.path
//                 d={`M0,${waterClipY} L0,1 L1,1 L1,${waterClipY} C-1.5,${waterClipY + waveAmplitude} -4,${waterClipY + waveAmplitude / 2} 0,${waterClipY}`}
//                 animate={{
//                   d: [
//                     `M0,${waterClipY} L0,1 L1,1 L1,${waterClipY} C-1.5,${waterClipY + 0.05} -4,${waterClipY + 0.03} 0,${waterClipY}`,
//                     `M0,${waterClipY} L0,1 L1,1 L1,${waterClipY} C4,${waterClipY + 0.02} 1.5,${waterClipY + 0.05} 0,${waterClipY}`,
//                     `M0,${waterClipY} L0,1 L1,1 L1,${waterClipY} C-1.5,${waterClipY + 0.05} -4,${waterClipY + 0.02} 0,${waterClipY}`,
//                   ],
//                 }}
//                 transition={{
//                   repeat: Number.POSITIVE_INFINITY,
//                   duration: 5,
//                   ease: "easeInOut",
//                 }}
//               />
//             </clipPath>

//             <clipPath id="wave2-clip" clipPathUnits="objectBoundingBox">
//               <motion.path
//                 d={`M0,${waterClipY} L0,1 L1,1 L1,${waterClipY} C-1,${waterClipY + waveAmplitude} -5,${waterClipY + waveAmplitude / 2} 0,${waterClipY}`}
//                 animate={{
//                   d: [
//                     `M0,${waterClipY} L0,1 L1,1 L1,${waterClipY} C-1,${waterClipY + 0.05} -5,${waterClipY + 0.03} 0,${waterClipY}`,
//                     `M0,${waterClipY} L0,1 L1,1 L1,${waterClipY} C5,${waterClipY + 0.02} 1,${waterClipY + 0.05} 0,${waterClipY}`,
//                     `M0,${waterClipY} L0,1 L1,1 L1,${waterClipY} C-1,${waterClipY + 0.05} -5,${waterClipY + 0.02} 0,${waterClipY}`,
//                   ],
//                 }}
//                 transition={{
//                   repeat: Number.POSITIVE_INFINITY,
//                   duration: 5,
//                   ease: "easeInOut",
//                 }}
//               />
//             </clipPath>
//           </>
//         )}
//       </svg>
//     </div>
//   );
// };
