/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormBoxSwitch } from "@/components/Forms/Inputs/BoxSwitchComponent";
import { HealthSectionValidationSchema } from "@/helper/Schemas/OnboardingSchemas";
import { useStepper } from "@/helper/hooks/useStepper";
import { fieldTypeBuilder } from "@/helper/lib/utils";
import {
  UserAllergy,
  UserHealthProfiles,
  alcoholField,
  allergiesField,
  existingConditions,
  smokingField,
} from "@/helper/static/Onboarding/values";
import { useBoardingStore } from "@/helper/store/boardingStore";
import { MultiStepComponentFormWrapper } from "../MultiStepFormWrapper";
import { BoardingMultiSelect, BoardingRow } from "../UIElements";

export function HealthProfile() {
  const {
    boarding,
    setBoardingUserHealthProfiles,
    nextStep,
    setStepValidity,
    stepNumber,
    setBoardingUserAllergy,
  } = useBoardingStore();

  const initValues = {
    UserHealthProfiles: {
      [existingConditions]: boarding.UserHealthProfiles.existingConditions,
      [smokingField]: boarding.UserHealthProfiles.smokingStatus,
      [alcoholField]: boarding.UserHealthProfiles.alcoholConsumption,
    },
    UserAllergy: {
      [allergiesField]: boarding.UserAllergy.allergies,
    },
  };
  const { nextStep: stepperNextFunction } = useStepper();

  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  const onSubmitHandler = (values: any) => {
    setBoardingUserHealthProfiles(values.UserHealthProfiles);
    setBoardingUserAllergy(values.UserAllergy);
    stepperNextFunction();
    nextStep();
    setStepValidity(stepNumber, true);
  };

  // TODO yes no kısımlarını bir farklı array ile statik hale getir
  return (
    <MultiStepComponentFormWrapper
      initValues={initValues}
      onSubmit={onSubmitHandler}
      validationSchema={HealthSectionValidationSchema}
    >
      <div className="flex flex-col items-center justify-between gap-2 sm:flex-row">
        <FormBoxSwitch
          name={fieldTypeBuilder(UserHealthProfiles, smokingField)}
          options={[
            { label: "No", value: false },
            { label: "Yes", value: true },
          ]}
          label="Sigara içiyor musunuz?"
        />
        <FormBoxSwitch
          name={fieldTypeBuilder(UserHealthProfiles, alcoholField)}
          options={[
            { label: "No", value: false },
            { label: "Yes", value: true },
          ]}
          label="Alkol kullanıyor musunuz?"
        />
      </div>
      <BoardingRow col extraClasses="gap-2">
        {/* TODO bunu comboboxa dönüştür veya multiselect yap */}
        <BoardingMultiSelect
          Name={fieldTypeBuilder(UserAllergy, allergiesField)}
          Placeholder="Alerjileriniz"
          searchPlaceholder="Alerjilerinizi seçiniz"
          allergiesOrConditions="allergies"
        />
        <BoardingMultiSelect
          Name={fieldTypeBuilder(UserHealthProfiles, existingConditions)}
          Placeholder="Existing Conditions"
          searchPlaceholder="Choose your existing conditions"
          allergiesOrConditions="conditions"
        />
      </BoardingRow>
    </MultiStepComponentFormWrapper>
  );
}
