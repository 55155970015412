import { DatePicker } from "@/components/ui/DatePicker";
import { useMediaQuery } from "@/helper/hooks/useMediaQuery";
import type { FC } from "react";
import { DestructedFormikField } from "./DestructedField";

interface FormDatePickerProps {
  FieldName: string;
  numberOfMonths?: number;
  MonthAndYearPicker?: boolean;
}

export const FormDatePicker: FC<FormDatePickerProps> = ({
  FieldName,
  numberOfMonths,
  MonthAndYearPicker,
}) => {
  const isDesktop = useMediaQuery("(min-width: 768px)");
  return (
    <DestructedFormikField FieldName={FieldName}>
      {({ field, form }) => {
        // Directly access the field.onChange function
        const handleDateChange = (date: Date) => {
          // Set the correct field value for Formik
          form.setFieldValue(field.name, date);
        };

        return (
          <DatePicker
            formValue={field.value}
            setValue={handleDateChange as (value: Date | undefined) => void}
            numberOfMonths={(!isDesktop && 1) || numberOfMonths || 1}
            MonthAndYearDropdown={MonthAndYearPicker}
            widthClasses="w-full"
          />
        );
      }}
    </DestructedFormikField>
  );
};
