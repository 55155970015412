import { DestructedFormikField } from "@/components/Forms/Inputs/DestructedField";
import { InputError } from "@/components/Forms/Inputs/InputError";
import { InputLabelComponent } from "@/components/Forms/Inputs/InputLabel";
import { GoalsAndActivityValidationSchema } from "@/helper/Schemas/OnboardingSchemas";
import { fieldTypeBuilder } from "@/helper/lib/utils";
import { activityLevels, goals } from "@/helper/static/Onboarding/arrays";
import {
  UserHealthProfiles,
  activityLevelField,
  healthGoalField,
} from "@/helper/static/Onboarding/values";
import { useBoardingStore } from "@/helper/store/boardingStore";
import { MultiStepComponentFormWrapper } from "../MultiStepFormWrapper";
import { BoardingCheckboxes, BoardingCombobox } from "../UIElements";
import { useStepper } from "@/helper/hooks/useStepper";

export function GoalsAndActivity() {
  const HealthProfileFieldName = fieldTypeBuilder(
    UserHealthProfiles,
    healthGoalField,
  );

  const {
    boarding,
    setBoardingUserHealthProfiles,
    nextStep,
    stepNumber,
    setStepValidity,
    setReviewMode,
  } = useBoardingStore();

  const initValues = {
    UserHealthProfiles: {
      [activityLevelField]: boarding.UserHealthProfiles.activityLevel,
      [healthGoalField]: boarding.UserHealthProfiles.healthGoal,
    },
  };

  const { nextStep: stepperNextFunction } = useStepper();
  const onSubmitHandler = (values: typeof initValues) => {
    setBoardingUserHealthProfiles(values.UserHealthProfiles);
    setReviewMode(true);

    stepperNextFunction();
    nextStep();
    setStepValidity(stepNumber, true);
  };

  return (
    <MultiStepComponentFormWrapper
      initValues={initValues}
      onSubmit={onSubmitHandler}
      validationSchema={GoalsAndActivityValidationSchema}
    >
      <div className="flex w-full flex-col items-center justify-center gap-2 sm:flex-row">
        <div className={"lg:w-2/3"}>
          {/*TODO make this better, use combobox maybe ???*/}
          <DestructedFormikField FieldName={HealthProfileFieldName}>
            {({ field, form }) => (
              <div className="space-y-2 md:min-w-44">
                <InputLabelComponent
                  LabelText="Hedef Seçiniz"
                  FieldName={HealthProfileFieldName}
                />
                <div className="grid grid-flow-row grid-cols-2 gap-2 sm:grid-cols-3">
                  {goals.map((goal) => (
                    <BoardingCheckboxes
                      key={goal.value}
                      Text={goal.label}
                      Value={goal.value}
                      Field={{ ...field }}
                      Form={form}
                      FormKey={HealthProfileFieldName}
                    />
                  ))}
                </div>
              </div>
            )}
          </DestructedFormikField>
          <InputError Name={HealthProfileFieldName} />
        </div>
        <div className="grid gap-2">
          <BoardingCombobox
            FieldName={fieldTypeBuilder(UserHealthProfiles, activityLevelField)}
            Options={activityLevels}
            DefaultLabel="Aktivite Seviyesi Seçiniz"
            EmptyText="Aktivite seviyesi bulunamadı."
            PlaceholderText="Aktivite seviyesini arayın..."
            TooltipContent="Aktivite seviyenizi buradan seçebilirsiniz. Seçenekler arasında şunlar yer alır: Sedanter, Hafif Egzersiz (1-3 gün), Orta Egzersiz (3-5 gün), Ağır Egzersiz (6-7 gün), Profesyonel Sporcu veya fiziksel işçilik gerektiren yoğun aktiviteler."
          />
        </div>
      </div>
    </MultiStepComponentFormWrapper>
  );
}
