export function APIUrlBuilder(
  endpointUrl: string,
  queryParams?: Record<string, string>,
) {
  const base_url =
    import.meta.env.VITE_BACKEND_API_URL || "https://default-url.com";
  const api_url =
    import.meta.env.VITE_BACKEND_API_ENDPOINT || "/default-api-path";

  // Correctly format the URL by ensuring exactly one slash between segments
  const trimmedBaseUrl = base_url.replace(/\/+$/, ""); // Remove trailing slashes
  const trimmedApiUrl = api_url.replace(/^\/+|\/+$/g, ""); // Remove leading/trailing slashes
  const trimmedEndpointUrl = endpointUrl.replace(/^\/+/, ""); // Remove leading slashes

  const fullUrl = `${trimmedBaseUrl}/${trimmedApiUrl}/${trimmedEndpointUrl}`;

  // Building query string manually if queryParams are provided
  let queryString = "";
  if (queryParams) {
    queryString = Object.entries(queryParams)
      .map(([key, value]) => {
        return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
      })
      .join("&");
  }

  return queryString ? `${fullUrl}?${queryString}` : fullUrl;
}
