import { cn } from "@/helper/lib/utils";
import type { BoxSwitchProps, OptionValue } from "@/helper/types/BoxSwitch";
import * as RadioGroup from "@radix-ui/react-radio-group";
import { motion } from "framer-motion";
import * as React from "react";

const SwitchBackground = ({
  dimensions,
}: {
  dimensions: { width: number; left: number };
}) => {
  return (
    <motion.div
      className="absolute left-0 top-0 h-full rounded-lg bg-primary"
      animate={{
        width: dimensions.width,
        x: dimensions.left,
      }}
      transition={{
        type: "spring",
        stiffness: 500,
        damping: 30,
      }}
    />
  );
};

const BoxSwitch: React.FC<BoxSwitchProps> = ({
  options,
  value,
  onChange,
  valueKey = "value",
  labelKey = "label",
  ...props
}) => {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [dimensions, setDimensions] = React.useState({ width: 0, left: 0 });
  const containerRef = React.useRef<HTMLDivElement>(null);
  const itemRefs = React.useRef<HTMLDivElement[]>([]);

  React.useEffect(() => {
    let index = options.findIndex((option) => {
      if (typeof option === "string") {
        return option === value;
      } else if (typeof option === "object" && option !== null) {
        return option[valueKey] === value;
      }
      return false;
    });

    if (index === -1) {
      index = 0;
    }

    setActiveIndex(index);

    if (itemRefs.current[index]) {
      const rect = itemRefs.current[index].getBoundingClientRect();
      const containerRect = containerRef.current?.getBoundingClientRect();
      setDimensions({
        width: rect.width,
        left: rect.left - (containerRect?.left || 0),
      });
    }
  }, [value, options, valueKey]);

  const handleChange = (index: number) => {
    // Önce index'in geçerli olup olmadığını kontrol edelim
    if (index < 0 || index >= options.length) {
      console.error(
        `Invalid index: ${index}. It is out of bounds for options array.`,
      );
      return;
    }

    const selectedOption = options[index];
    setActiveIndex(index);

    if (onChange) {
      if (typeof selectedOption === "object" && selectedOption !== null) {
        const value = selectedOption[valueKey];
        if (value !== undefined) {
          onChange(value as OptionValue);
        } else {
          console.error(
            `The value for key "${valueKey}" is undefined in selectedOption.`,
          );
        }
      } else if (typeof selectedOption === "string") {
        onChange(selectedOption);
      } else {
        console.error(
          `Selected option is of invalid type: ${typeof selectedOption}. Expected string or OptionObject.`,
        );
      }
    }
  };

  return (
    <RadioGroup.Root
      value={value?.toString()} // `value`'yu string olarak aktar
      onValueChange={(val) => {
        const index = options.findIndex((option) => {
          if (typeof option === "string") {
            return option === val;
          } else if (typeof option === "object" && option !== null) {
            return option[valueKey]?.toString() === val;
          }
          return false;
        });

        if (index === -1) {
          console.error(`Value "${val}" not found in options array.`);
          return;
        }

        handleChange(index);
      }}
      className="relative flex h-10 rounded-md bg-background"
      ref={containerRef}
      {...props}
    >
      <SwitchBackground dimensions={dimensions} />
      {options.map((option, index) => (
        <RadioGroup.Item
          key={index}
          value={
            typeof option === "string"
              ? option
              : typeof option === "object" && option !== null
                ? option[valueKey]?.toString()
                : "" // Geçersiz türler için boş string döndür
          }
          asChild
        >
          <div
            ref={(el) => (itemRefs.current[index] = el!)}
            className={cn(
              "z-10 flex flex-1 cursor-pointer items-center justify-center rounded-lg px-4 text-sm font-medium",
              index === activeIndex ? "text-primary-foreground" : "",
            )}
            onClick={() => handleChange(index)}
          >
            {
              typeof option === "string"
                ? option
                : typeof option === "object" && option !== null
                  ? option[labelKey]?.toString()
                  : "" // Geçersiz türler için boş string döndür
            }
          </div>
        </RadioGroup.Item>
      ))}
    </RadioGroup.Root>
  );
};

BoxSwitch.displayName = "BoxSwitch";

export { BoxSwitch };
