import * as Sentry from "@sentry/react";
import { StrictMode, useEffect } from "react";
import { createRoot } from "react-dom/client";
import {
  RouterProvider,
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import LoadingComponent from "./components/LoadingComponent.tsx";
import Provider from "./components/Provider.tsx";
import Layout from "./components/ui/Layout.tsx";
import ErrorPage from "./pages/ErrorPage.tsx";
import { routes } from "./routes";
import "./styles/index.css";

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: import.meta.env.DEV ? 1 : 0.5,
  // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/], //TODO: check if this is not blocked because of cors, and if its is not needed remove it

  // Session Replay
  replaysSessionSampleRate: import.meta.env.DEV ? 1 : 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

let router: ReturnType<typeof createBrowserRouter>;

if (import.meta.env.DEV) {
  router = createBrowserRouter(routes);
}

router = sentryCreateBrowserRouter(routes);

const root = createRoot(document.getElementById("kalori") as HTMLElement);

// Debug mode class
if (import.meta.env.DEV) {
  document.body.className += " debug-screens";
}

// Production console disable
if (import.meta.env.PROD) {
  console.log = () => {};
  console.warn = () => {};
  console.error = () => {};
}

root.render(
  <StrictMode>
    <Sentry.ErrorBoundary fallback={<ErrorPage />}>
      <Layout>
        <Provider>
          <RouterProvider
            router={router}
            fallbackElement={<LoadingComponent isFullPage />}
          />
        </Provider>
      </Layout>
    </Sentry.ErrorBoundary>
  </StrictMode>,
);
