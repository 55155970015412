import { format } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";
import * as React from "react";

import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { cn } from "@/helper/lib/utils";

export function DatePicker({
  formValue,
  setValue,
  numberOfMonths,
  MonthAndYearDropdown,
  classes,
  widthClasses,
}: {
  formValue?: Date;
  setValue?: (value: Date | undefined) => void;
  numberOfMonths?: number;
  MonthAndYearDropdown?: boolean;
  classes?: string;
  widthClasses?: string;
}) {
  const [date, setDate] = React.useState<Date | undefined>(formValue); // State başlangıç değeri formValue ile

  React.useEffect(() => {
    // formValue değiştiğinde date'i güncelle
    setDate(formValue);
  }, [formValue]);

  const handleDateChange = (newDate: Date | undefined) => {
    if (newDate) {
      // Convert the selected date to UTC to prevent time zone issues
      const utcDate = new Date(
        Date.UTC(
          newDate.getFullYear(),
          newDate.getMonth(),
          newDate.getDate(),
          0,
          0,
          0,
        ),
      );
      setDate(utcDate);
      if (setValue) {
        setValue(utcDate);
      }
    } else {
      setDate(undefined);
      if (setValue) {
        setValue(undefined);
      }
    }
  };

  return (
    <Popover modal>
      <PopoverTrigger asChild>
        <Button
          variant={"outline"}
          className={cn(
            classes ? classes : "w-[280px] justify-start text-left font-normal",
            widthClasses ? widthClasses : "w-[280px]",
            !date && "text-muted-foreground",
          )}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {date ? format(date, "PPP") : <span>Pick a date</span>}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        {/* TODO buna localization ekle */}
        <Calendar
          mode="single"
          selected={date}
          onSelect={handleDateChange}
          disabled={(date) =>
            date > new Date() || date < new Date("1900-01-01")
          }
          initialFocus
          numberOfMonths={numberOfMonths || 1}
          // locale={es}
          defaultMonth={formValue || new Date()}
          {...(MonthAndYearDropdown
            ? {
                captionLayout: "dropdown-buttons",
                fromYear: 1940,
                toYear: new Date().getFullYear(),
              }
            : {})}
          required
        />
      </PopoverContent>
    </Popover>
  );
}
