import type {
  AllergyFields,
  HealthProfileFields,
  SettingsFields,
  UserFields,
} from "@/helper/store/boardingStore";

export const User = "User";
export const UserHealthProfiles = "UserHealthProfiles";
export const UserAllergy = "UserAllergy";
export const UserSettings = "UserSettings";
// export const GoalProfile = "GoalProfileChooser";

export const nameField: UserFields = "firstName";
export const lastNameField: UserFields = "lastName";
export const usernameField: UserFields = "username";
export const birthDateField: HealthProfileFields = "birthDate";
export const genderField: HealthProfileFields = "gender";
export const heightField: HealthProfileFields = "height";
export const weightField: HealthProfileFields = "weight";
export const existingConditions: HealthProfileFields = "existingConditions";
export const smokingField: HealthProfileFields = "smokingStatus";
export const alcoholField: HealthProfileFields = "alcoholConsumption";
export const allergiesField: AllergyFields = "allergies";
export const activityLevelField: HealthProfileFields = "activityLevel";
export const healthGoalField: HealthProfileFields = "healthGoal";
export const languageField: SettingsFields = "language";
export const weightUnitField: SettingsFields = "weight_unit";
export const heightUnitField: SettingsFields = "height_unit";
export const waterUnitField: SettingsFields = "water_unit";
export const energyUnitField: SettingsFields = "energy_unit";
export const themeField: SettingsFields = "theme";
export const mealCountryField: SettingsFields = "preferred_meal_country";
