import { useSidebarStore } from "@/helper/store/sidebarStore";
import * as VisuallyHidden from "@radix-ui/react-visually-hidden";
import Logo from "../Logo";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from "../ui/sheet";
import { SidebarContent } from "./SidebarContent";

const SidebarMobile = () => {
  const { mobileSidebarOpen, setMobileSidebarOpen } = useSidebarStore();
  return (
    <Sheet open={mobileSidebarOpen} onOpenChange={setMobileSidebarOpen}>
      <SheetContent
        className="flex h-full flex-col bg-green-300 px-3 dark:bg-sidebar sm:w-72"
        side="left"
      >
        <>
          <VisuallyHidden.Root>
            <SheetDescription>Mobile Sidebar</SheetDescription>
            <SheetTitle>Mobile Sidebar</SheetTitle>
          </VisuallyHidden.Root>
        </>
        <SheetHeader>
          <div className="mx-auto flex items-center justify-start">
            <Logo />
          </div>
        </SheetHeader>
        <SidebarContent />
      </SheetContent>
    </Sheet>
  );
};
export default SidebarMobile;
