import { fieldTypeBuilder } from "@/helper/lib/utils";
import {
  heightUnits,
  waterUnits,
  weightUnits,
} from "@/helper/static/Onboarding/arrays";
import {
  UserHealthProfiles,
  heightField,
  weightField,
} from "@/helper/static/Onboarding/values";
import { useBoardingStore } from "@/helper/store/boardingStore";
import { useUnitStore } from "@/helper/store/unitStore";
import type { heightUnit, waterUnit, weightUnit } from "@/helper/types/Units";
import { type FC, type ReactNode, useEffect, useRef } from "react";
import { InputComponent } from "../Forms/Inputs/InputComponent";
import { InputError } from "../Forms/Inputs/InputError";
import { InputLabelComponent } from "../Forms/Inputs/InputLabel";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";

interface UnitSelectorProps {
  FieldID?: string;
  FieldName?: string;
  InputLabel?: string;
  Placeholder?: string;
  DefaultUnitValue?: string;
}
type Units = weightUnit | heightUnit;
type UnitsArray = {
  value: Units;
  label: string;
}[];
interface UnitInputProps {
  FieldID: string;
  FieldName: string;
  Placeholder: string;
  onChange: (value: Units) => void;
  defaultValue: Units;
  unitsArray: UnitsArray;
}

const Wrapper = ({ children }: { children: ReactNode }) => {
  return <div className="flex items-center justify-center">{children}</div>;
};

const UnitInput: FC<UnitInputProps> = ({
  FieldID,
  FieldName,
  Placeholder,
  onChange,
  defaultValue,
  unitsArray,
}) => {
  return (
    <Wrapper>
      <InputComponent
        Name={FieldName}
        Placeholder={Placeholder}
        InputType="number"
        FieldId={FieldID}
        classes="rounded-r-none block border-r-0 w-full"
      />
      <Select onValueChange={onChange} defaultValue={defaultValue}>
        <SelectTrigger className="max-w-[130px] rounded-l-none border-l-0">
          <SelectValue placeholder="Weight Unit" />
        </SelectTrigger>
        <SelectContent>
          {unitsArray.map((item) => {
            return (
              <SelectItem key={item.value} value={item.value}>
                {item.label}
              </SelectItem>
            );
          })}
        </SelectContent>
      </Select>
    </Wrapper>
  );
};

interface ComponentProps extends UnitInputProps {
  LabelText: string;
  TooltipContent: string;
}

const UnitComponent: FC<ComponentProps> = ({
  FieldID,
  FieldName,
  Placeholder,
  onChange,
  defaultValue,
  unitsArray,
  LabelText,
  TooltipContent,
}) => {
  return (
    <div className="w-full space-y-2">
      <InputLabelComponent
        For={FieldID}
        LabelText={LabelText}
        FieldName={FieldName}
        tooltip
        Content={TooltipContent}
      />
      <UnitInput
        FieldID={FieldID}
        FieldName={FieldName}
        Placeholder={Placeholder}
        onChange={onChange}
        defaultValue={defaultValue}
        unitsArray={unitsArray}
      />
      <InputError Name={FieldName} />
    </div>
  );
};

export const WeightInput: FC<UnitSelectorProps> = ({
  FieldID = "weight",
  FieldName = fieldTypeBuilder(UserHealthProfiles, weightField),
  InputLabel,
  Placeholder = "Kilo",
  DefaultUnitValue,
}) => {
  const { boarding } = useBoardingStore();
  const InputLabelText = `Kilonuzu giriniz(${boarding.UserSettings.weight_unit})`;
  const { setWeightUnit, selectedWeightUnit } = useUnitStore();

  // Use a ref to track if the initial setup has been done
  const initialSetupDone = useRef(false);

  useEffect(() => {
    // Only set the default weight unit once, when the component mounts
    if (!initialSetupDone.current && DefaultUnitValue) {
      setWeightUnit(DefaultUnitValue as weightUnit);
      initialSetupDone.current = true;
    }
  }, [DefaultUnitValue, setWeightUnit]);

  const onChangeHandler = (value: weightUnit) => {
    setWeightUnit(value);
  };

  return (
    <UnitComponent
      FieldID={FieldID}
      FieldName={FieldName}
      Placeholder={Placeholder}
      onChange={onChangeHandler as (value: Units) => void}
      defaultValue={selectedWeightUnit as Units}
      unitsArray={weightUnits as UnitsArray}
      LabelText={InputLabel ?? InputLabelText}
      TooltipContent="Kilonuzu girerken hangi birimi kullanmak istediğinizi seçin. Seçtiğiniz birim, kilonuzun hesaplanmasında kullanılacaktır. Varsayılan birim, parantez içinde ve seçim alanında varsayılan olarak gösterilmiştir."
    />
  );
};

export const HeightInput: FC<UnitSelectorProps> = ({
  FieldID = "height",
  FieldName = fieldTypeBuilder(UserHealthProfiles, heightField),
  InputLabel,
  Placeholder = "Boy",
  DefaultUnitValue,
}) => {
  const { boarding } = useBoardingStore();
  const InputLabelText = `Boyunuzu giriniz(${boarding.UserSettings.height_unit})`;
  const { setHeightUnit, selectedHeightUnit } = useUnitStore();

  // Use a ref to track if the initial setup has been done
  const initialSetupDone = useRef(false);

  useEffect(() => {
    // Only set the default height unit once, when the component mounts
    if (!initialSetupDone.current && DefaultUnitValue) {
      setHeightUnit(DefaultUnitValue as heightUnit);
      initialSetupDone.current = true;
    }
  }, [DefaultUnitValue, setHeightUnit]);

  const onChangeHandler = (value: heightUnit) => {
    setHeightUnit(value);
  };

  return (
    <UnitComponent
      FieldID={FieldID}
      FieldName={FieldName}
      Placeholder={Placeholder}
      onChange={onChangeHandler as (value: Units) => void}
      defaultValue={selectedHeightUnit as Units}
      unitsArray={heightUnits as UnitsArray}
      LabelText={InputLabel ?? InputLabelText}
      TooltipContent="Boyunuzu girerken tercih ettiğiniz birimi seçin. Seçtiğiniz birim, boyunuzun hesaplanmasında esas alınacaktır. Varsayılan birim, parantez içinde ve seçim alanında varsayılan değer olarak gösterilmiştir."
    />
  );
};

export const WaterInput: FC<UnitSelectorProps> = ({
  FieldID = "water",
  FieldName = "water_intake",
  InputLabel,
  Placeholder = "Water",
  DefaultUnitValue,
}) => {
  const InputLabelText = `Suyunuzu giriniz(${DefaultUnitValue})`;
  const { setWaterUnit, selectedWaterUnit } = useUnitStore();

  // Use a ref to track if the initial setup has been done
  const initialSetupDone = useRef(false);

  useEffect(() => {
    // Only set the default water unit once, when the component mounts
    if (!initialSetupDone.current && DefaultUnitValue) {
      setWaterUnit(DefaultUnitValue as waterUnit);
      initialSetupDone.current = true;
    }
  }, [DefaultUnitValue, setWaterUnit]);

  const onChangeHandler = (value: waterUnit) => {
    setWaterUnit(value);
  };
  return (
    <UnitComponent
      FieldID={FieldID}
      FieldName={FieldName}
      Placeholder={Placeholder}
      onChange={onChangeHandler as (value: Units) => void} // Explicit cast
      defaultValue={selectedWaterUnit as Units}
      unitsArray={waterUnits as UnitsArray}
      LabelText={InputLabel ?? InputLabelText}
      TooltipContent="Su miktarınızı girdiğiniz birimi seçin. Bu seçim, su miktarınızın hesaplanmasında kullanılacaktır. Varsayılan birim, parantez içinde ve seçim alanında varsayılan olarak gösterilmiştir."
    />
  );
};
