import debounce from "lodash/debounce";
import { APIUrlBuilder } from "../ApiUrlbuilder";
import { fetchData } from "../fetch";

const checkUsernameAvailability = async (
  username: string,
  setError: (error: string) => void,
) => {
  const endpoint = APIUrlBuilder("/onboarding/check-username-unique", {
    username,
  });
  try {
    const data = await fetchData(endpoint); // Use fetchWithToken here
    const isUnique = data.is_unique;

    if (!isUnique) {
      setError("Bu kullanıcı adı mevcut. Lütfen başka bir tane deneyin.");
    } else {
      setError("");
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      if (
        "response" in error &&
        typeof error.response === "object" &&
        error.response &&
        "status" in error.response
      ) {
        if (error.response.status === 429) {
          setError(
            "Çok fazla istek gönderdiniz. Lütfen daha sonra tekrar deneyin.",
          );
        } else {
          console.error("Kullanıcı adı kontrolü sırasında hata:", error);
          setError("Kullanıcı adı kontrolü sırasında bir hata oluştu.");
        }
      } else {
        console.error("Kullanıcı adı kontrolü sırasında hata:", error);
        setError("Kullanıcı adı kontrolü sırasında bir hata oluştu.");
      }
    } else {
      console.error("Bilinmeyen bir hata oluştu");
      setError("Bilinmeyen bir hata oluştu");
    }
  }
};

export const debouncedCheckUsername = debounce(checkUsernameAvailability, 1200);
