/* eslint-disable @typescript-eslint/no-explicit-any */
import { Checkbox } from "@/components/ui/checkbox";
import { Label } from "@/components/ui/label";
import { cn } from "@/helper/lib/utils";
import type { FC } from "react";

interface CheckboxesProps {
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  Field: any;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  Form: any;
  Value: string;
  Text: string;
  Name: string;
  additionalText?: string;
}

export const FormCheckboxesAsRadio: FC<CheckboxesProps> = ({
  Field,
  Form,
  Name,
  Text,
  Value,
  additionalText = false,
}) => {
  function updateFieldValue() {
    if (Field.value === Value) {
      Form.setFieldValue(Name, "");
    } else {
      Form.setFieldValue(Name, Value);
    }
  }

  return (
    <div
      className={cn(
        "flex space-x-2",
        additionalText ? "items-top" : "items-center",
      )}
    >
      <Checkbox
        id={Name}
        name={Name}
        onClick={updateFieldValue}
        value={Value}
        checked={Field.value === Value}
        {...Field}
      />
      {additionalText ? (
        <div className="grid gap-1.5 leading-none">
          <Label htmlFor={Name}>{Text}</Label>
          <p className="text-sm text-muted-foreground">{additionalText}</p>
        </div>
      ) : (
        <Label htmlFor={Name}>{Text}</Label>
      )}
    </div>
  );
};
