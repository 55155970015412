import DailyCalorieCard from "@/components/Home/DailyCalorieCard";
import DailyCalorieChart from "@/components/Home/DailyCalorieChart";
import WaterGlass from "@/components/Home/WaterCard/WaterCard";
import HomeWelcomeElement from "@/components/Home/WelcomeElement";
// import { useUserStore } from "@/helper/store/userStore";
// import { PageLoader } from "@/components/LoadingComponent";

function HomePage() {
  return (
    <>
      <HomeWelcomeElement />
      <div className="container mx-auto">
        <div className="flex h-full flex-wrap gap-4 pt-4">
          <WaterGlass dailyWaterGoal={2500} waterConsumed={1500} />
          <div className="flex-none">
            <DailyCalorieChart />
          </div>

          <DailyCalorieCard />
        </div>
      </div>
    </>
  );
}

export default HomePage;
