import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { Input } from "@/components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { APIUrlBuilder } from "@/helper/functions/ApiUrlbuilder";
import { WaterTransformer } from "@/helper/functions/converters";
import { formattedDateForGo } from "@/helper/functions/date";
import { fetchData } from "@/helper/functions/fetch";
import { useIsDesktop } from "@/helper/hooks/useMediaQuery";
import { waterIntakeQueryKey } from "@/helper/static/forms";
import { useAuthStore } from "@/helper/store/authStore";
import { useUserStore } from "@/helper/store/userStore";
import type { waterUnit } from "@/helper/types/Units";
import type { UserWaterIntake } from "@/helper/types/UserTypes";
import { useQuery } from "@tanstack/react-query";
import {
  type ColumnDef,
  type ColumnFiltersState,
  type SortingState,
  type VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { format } from "date-fns";
import { ArrowDown, ArrowUp, ArrowUpDown, Trash } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { DeleteAlert } from "../Forms/OptionsDropdown/DeleteDialog";
import { OptionDropdown } from "../Forms/OptionsDropdown/Dropdown";
import { EditDialog } from "../Forms/OptionsDropdown/EditDialog";
import { DatePicker } from "../ui/DatePicker";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../ui/card";
import { CustomSkeleton } from "../ui/skeleton";
import { useToast } from "../ui/use-toast";
import { WaterValidationSchema } from "@/helper/Schemas/water";

interface ResponseData {
  intake_records: UserWaterIntake[];
  preferred_unit: waterUnit;
}

const getColumns = (
  refetch: () => void,
  userPreferredUnit: waterUnit,
): ColumnDef<UserWaterIntake>[] => [
  {
    id: "select",
    header: ({ table }) => (
      <Checkbox
        checked={
          table.getIsAllPageRowsSelected() ||
          (table.getIsSomePageRowsSelected() && "indeterminate")
        }
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "amount",
    header: ({ column }) => {
      const isSortedAsc = column.getIsSorted() === "asc";
      const isSortedDesc = column.getIsSorted() === "desc";
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {`Amount (${userPreferredUnit ?? "ml"})`}
          {isSortedAsc ? (
            <ArrowUp className="ml-2 h-4 w-4" />
          ) : isSortedDesc ? (
            <ArrowDown className="ml-2 h-4 w-4" />
          ) : (
            <ArrowUpDown className="ml-2 h-4 w-4" />
          )}
        </Button>
      );
    },
    cell: ({ row }) => {
      const amount = Number.parseFloat(row.getValue("amount"));
      return (
        <div className="text-center">
          {`${WaterTransformer(amount, "ml", userPreferredUnit)} ${userPreferredUnit}`}
        </div>
      );
    },
    enableColumnFilter: true,
    enableSorting: true,
    filterFn: (row, columnId, filterValue) => {
      return Number(row.getValue(columnId)) >= Number(filterValue);
    },
  },
  {
    accessorKey: "date",
    header: ({ column }) => {
      const isSortedAsc = column.getIsSorted() === "asc";
      const isSortedDesc = column.getIsSorted() === "desc";

      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Time
          {isSortedAsc ? (
            <ArrowDown className="ml-2 h-4 w-4" />
          ) : isSortedDesc ? (
            <ArrowUp className="ml-2 h-4 w-4" />
          ) : (
            <ArrowUpDown className="ml-2 h-4 w-4" />
          )}
        </Button>
      );
    },
    cell: ({ row }) => {
      const time = format(new Date(row.getValue("date")), "HH:mm");
      return <div>{time}</div>;
    },
    enableColumnFilter: true,
    enableSorting: true,
    filterFn: (row, columnId, filterValue) => {
      const time = format(new Date(row.getValue(columnId)), "HH:mm");
      return time.startsWith(filterValue);
    },
  },
  {
    id: "actions",
    enableHiding: false,
    cell: ({ row }) => {
      const intake = row.original;
      const editEndpoint = APIUrlBuilder(
        `/water/water-intake/${intake.intake_id}`,
      );
      return (
        <>
          <OptionDropdown />
          <EditDialog
            DefaultValue={intake.amount}
            FieldName="water_intake"
            FieldLabel="Water intake"
            Endpoint={editEndpoint}
            OnSuccess={refetch}
            Placeholder="Water intake"
            ValidationSchema={WaterValidationSchema}
            Description="Edit the water intake amount here. Click Save changes when you're done."
            Title="Edit Water"
            BodyKey="Amount"
          />
          <DeleteAlert
            Endpoint={editEndpoint}
            FieldLabel="Water intake"
            OnSuccess={refetch}
          />
        </>
      );
    },
  },
];

export function WaterDataTable() {
  const { UUID } = useAuthStore();
  const [userDate, setUserDate] = useState(new Date());
  const { UserSettings, setUserSettings } = useUserStore();
  const { toast } = useToast();
  const endpoint = APIUrlBuilder(
    `/water/water-intake/${UUID}/${formattedDateForGo(userDate)}`,
  );
  const {
    data: WaterData,
    isError,
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryFn: () => fetchData(endpoint),
    queryKey: [waterIntakeQueryKey, UUID, userDate],
    enabled: !!UUID,
    select: (data) => {
      // Filter out the items that are softly deleted
      return data.intakes as ResponseData;
    },
  });

  useEffect(() => {
    if (
      WaterData?.preferred_unit &&
      WaterData.preferred_unit !== UserSettings.water_unit
    ) {
      setUserSettings((prevSettings) => ({
        ...prevSettings,
        water_unit: WaterData.preferred_unit,
      }));
    }
  }, [WaterData?.preferred_unit, UserSettings.water_unit, setUserSettings]);

  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = useState({});
  const columns = getColumns(refetch, UserSettings.water_unit);

  const IntakeArray = WaterData?.intake_records;

  const data = useMemo(() => {
    return Array.isArray(IntakeArray) ? IntakeArray : [];
  }, [IntakeArray]);

  const table = useReactTable({
    data: data,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
    initialState: {
      sorting: [
        {
          id: "amount",
          desc: false,
        },
      ],
    },
  });

  const handleDeleteSelected = async () => {
    const selectedRowIds = Object.keys(rowSelection);
    const waterIntakeIds = selectedRowIds.map(
      (rowId) => table.getRow(rowId).original.intake_id,
    );

    try {
      const deleteEndpoint = APIUrlBuilder("/water/water-intake/bulk-delete");

      const data = await fetchData(
        deleteEndpoint,
        "DELETE",
        { intake_ids: waterIntakeIds },
        false,
      );

      if (data.ok) {
        toast({
          title: "Water intake records deleted!",
          description: `Successfully deleted ${waterIntakeIds.length} meal${waterIntakeIds.length > 1 ? "s" : ""}.`,
        });
        refetch(); // Refresh the data after all deletions are done
      } else {
        toast({
          variant: "destructive",
          title: "Uh oh! Something went wrong.",
          description:
            "There was a problem with your request. Try again later.",
        });
      }
    } catch (error) {
      console.error("Failed to delete water intake", error);
      toast({
        variant: "destructive",
        title: "Uh oh! Something went wrong.",
        description: "There was a problem with your request. Try again later.",
      });
    }
  };

  const isDesktop = useIsDesktop();

  if (isError) {
    return <div>Error: {error.message}</div>;
  }
  return (
    <Card>
      <div className="flex flex-col items-center justify-between md:flex-row">
        <CardHeader>
          <CardTitle>Water Intake</CardTitle>
          <CardDescription>
            Here are the water intakes for the day. You can edit or delete them
            if you need to.
          </CardDescription>
        </CardHeader>
        <div className="md:mr-6">
          <DatePicker
            formValue={userDate}
            setValue={setUserDate as (value: Date | undefined) => void}
            widthClasses="max-sm:w-full"
          />
        </div>
      </div>
      <CardContent>
        {isLoading ? (
          [1, 2, 3].map((i) => (
            <CustomSkeleton key={i} className="my-4 h-8 w-full" />
          ))
        ) : (
          <>
            <div className="mb-4 flex items-center justify-between max-md:pt-4">
              <div className="flex flex-col max-md:gap-2 md:flex-row md:space-x-2">
                <Input
                  placeholder="Filter amount..."
                  value={
                    (table.getColumn("amount")?.getFilterValue() as string) ??
                    ""
                  }
                  onChange={(event) =>
                    table
                      .getColumn("amount")
                      ?.setFilterValue(event.target.value)
                  }
                  className="max-w-sm"
                />
                <Input
                  placeholder="Filter time..."
                  value={
                    (table.getColumn("date")?.getFilterValue() as string) ?? ""
                  }
                  onChange={(event) =>
                    table.getColumn("date")?.setFilterValue(event.target.value)
                  }
                  className="max-w-sm"
                />
              </div>
              <Button
                variant="destructive"
                size={isDesktop ? "default" : "icon"}
                onClick={handleDeleteSelected}
                disabled={table.getFilteredSelectedRowModel().rows.length === 0}
              >
                {isDesktop ? "Delete Selected" : <Trash />}
              </Button>
            </div>
            <>
              <Table>
                <TableHeader>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <TableRow key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <TableHead key={header.id} className="sm:text-center">
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext(),
                              )}
                        </TableHead>
                      ))}
                    </TableRow>
                  ))}
                </TableHeader>
                <TableBody>
                  {table.getRowModel().rows?.length ? (
                    table.getRowModel().rows.map((row) => (
                      <TableRow
                        key={row.id}
                        data-state={row.getIsSelected() && "selected"}
                      >
                        {row.getVisibleCells().map((cell) => (
                          <TableCell key={cell.id} className="sm:text-center">
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={columns.length}
                        className="h-24 text-center"
                      >
                        No results.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </>
          </>
        )}
      </CardContent>
    </Card>
  );
}
