import { QueryClient } from "@tanstack/react-query";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 30, // 30 seconds for regular queries
    },
  },
});

// this is for data that changes often but doesn't need to be refreshed often
export const regularQueryOptions = {
  staleTime: 1000 * 60 * 3, // 3 minutes
  gcTime: 1000 * 60 * 10, // Cache for 10 minutes
};

// this is for data that doesn't need to be refreshed often, for like food items
export const staleQueryOptions = {
  staleTime: 1000 * 60 * 15, // 15 minutes
  gcTime: 1000 * 60 * 10, // Cache for 10 minutes
};

// Specific options for route-loaded queries
export const routeQueryOptions = {
  staleTime: Number.POSITIVE_INFINITY, // Never mark route-loaded data as stale
  gcTime: 1000 * 60 * 60, // Cache for 1 hour
  retry: false, // Don't retry failed requests
};
