import { MoonIcon, SunIcon } from "@heroicons/react/24/outline";

import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { type ThemeState, useTheme } from "@/helper/store/themeStore";
import type { FC } from "react";

interface Props {
  customFunction?: (fieldValue: string | undefined, theme: ThemeState) => void;
  fieldName?: string;
  buttonClasses?: string;
  size?: "sm" | "lg" | "icon" | "default" | null | undefined;
  dropdownMenuAlignment?: "start" | "end" | "center" | undefined;
}

const ThemeToggle: FC<Props> = ({
  customFunction,
  fieldName,
  size,
  buttonClasses,
  dropdownMenuAlignment,
}) => {
  const { setTheme } = useTheme();

  const themeChange = (theme: ThemeState) => {
    setTheme(theme);
    customFunction?.(fieldName, theme);
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="outline"
          size={size ?? "icon"}
          className={buttonClasses}
        >
          <SunIcon className="h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0" />
          <MoonIcon className="absolute h-[1.2rem] w-[1.2rem] rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100" />
          <span className="sr-only">Toggle theme</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align={dropdownMenuAlignment ?? "end"}>
        <DropdownMenuItem onClick={() => themeChange("light")}>
          Light
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => themeChange("dark")}>
          Dark
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => themeChange("system")}>
          System
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
export default ThemeToggle;
