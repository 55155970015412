import { OnboardingForm } from "@/components/Onboarding/OnboardingForm";
import { Toaster } from "@/components/ui/toaster";
import { useAuthStore } from "@/helper/store/authStore";
import { useBoardingStore } from "@/helper/store/boardingStore";
import clsx from "clsx";
import { useEffect } from "react";
import { OnboardingStepperComponent } from "../components/Onboarding/OnboardingStepper";

// TODO: Add validation for custom calorie goal and daily water intake inputs.
// TODO: Update array fields to use comboboxes and handle array conversion.
// TODO: Seperate the logic between goals and preferences.

//! TODO: Convert array inputs to comboboxes and prefill with data from the database, e.g., allergies and existing conditions.
// Prefill activityLevels, languages, weightUnits, heightUnits, waterUnits, energyUnits, goals, themes from the database.

const OnboardingPage = () => {
  const { boarding, setBoardingUser, stepNumber } = useBoardingStore();
  const { user } = useAuthStore();

  useEffect(() => {
    if (user) {
      const email = user.email; // Firebase user email
      const uid = user.uid; // Firebase UID
      const provider = user.providerData[0].providerId;
      const providerProfilePicture = user.providerData[0].photoURL;

      if (email && email !== boarding.User.email) {
        setBoardingUser({ email });
      }
      if (uid && uid !== boarding.User.socialID) {
        setBoardingUser({ socialID: uid }); // Store Firebase UID as socialID
      }
      if (provider && provider !== boarding.User.provider) {
        setBoardingUser({ provider });
      }
      if (
        providerProfilePicture &&
        providerProfilePicture !== boarding.User.providerProfilePicture
      ) {
        setBoardingUser({ providerProfilePicture }); // Store provider profile picture
      }
    }
  }, [boarding.User, setBoardingUser, user]);

  return (
    <>
      <main className="mx-auto flex h-full min-h-dvh w-full flex-col items-center justify-center sm:py-4 md:max-w-4xl lg:max-w-5xl xl:max-w-7xl">
        <div
          className={clsx(
            "max-sm:px-4",
            stepNumber !== -1 &&
              "grid h-full grid-flow-row items-center justify-items-center gap-2",
            stepNumber !== -1 && "w-full",
          )}
        >
          <OnboardingStepperComponent>
            <OnboardingForm />
          </OnboardingStepperComponent>
        </div>
        <Toaster />
      </main>
    </>
  );
};

export default OnboardingPage;
