import type { waterUnit } from "../types/Units";

// WeightTransformer function takes weight and unit as input and returns the weight in the desired unit.
export const WeightTransformer = (
  weight: number,
  fromUnit: string,
  toUnit: string,
): number => {
  // Convert the input weight to grams first
  let weightInGrams: number;
  switch (fromUnit.toLowerCase()) {
    case "g":
      weightInGrams = weight;
      break;
    case "kg":
      weightInGrams = weight * 1000;
      break;
    case "lb":
      weightInGrams = weight * 453.592;
      break;
    case "oz":
      weightInGrams = weight * 28.3495;
      break;
    default:
      weightInGrams = weight; // Assume the input is already in grams if unit is unknown
      break;
  }

  // Convert from grams to the target unit
  switch (toUnit.toLowerCase()) {
    case "g":
      return weightInGrams;
    case "kg":
      return weightInGrams / 1000;
    case "lb":
      return weightInGrams / 453.592;
    case "oz":
      return weightInGrams / 28.3495;
    default:
      return weightInGrams; // Return the weight in grams if target unit is unknown
  }
};

// HeightTransformer function takes height and unit as input and returns the height in the desired unit.
export const HeightTransformer = (
  height: number,
  fromUnit: string,
  toUnit: string,
): number => {
  // Convert the input height to centimeters first
  let heightInCm: number;
  switch (fromUnit.toLowerCase()) {
    case "cm":
      heightInCm = height;
      break;
    case "m":
      heightInCm = height * 100;
      break;
    case "in":
      heightInCm = height * 2.54;
      break;
    case "ft": {
      const feet = Math.floor(height);
      const inches = (height - feet) * 12; // Convert the fractional part to inches
      heightInCm = feet * 30.48 + inches * 2.54;
      break;
    }
    default:
      heightInCm = height; // Assume the input is already in centimeters if unit is unknown
      break;
  }

  // Convert from centimeters to the target unit
  switch (toUnit.toLowerCase()) {
    case "cm":
      return heightInCm;
    case "m":
      return heightInCm / 100;
    case "in":
      return heightInCm / 2.54;
    case "ft": {
      const totalInches = heightInCm / 2.54;
      const feet = Math.floor(totalInches / 12);
      const inches = totalInches % 12;
      return feet + inches / 10; // Return feet with the fractional part as inches
    }
    default:
      return heightInCm; // Return the height in centimeters if target unit is unknown
  }
};

// WaterTransformer function takes water volume and unit as input and returns the volume in the desired unit.
export const WaterTransformer = (
  volume: number,
  fromUnit: waterUnit,
  toUnit: waterUnit,
): number => {
  // Convert the input volume to milliliters first
  let volumeInMl: number;
  switch (fromUnit.toLowerCase()) {
    case "ml":
      volumeInMl = volume;
      break;
    case "liters":
      volumeInMl = volume * 1000;
      break;
    case "oz":
      volumeInMl = volume * 29.5735;
      break;
    case "gal":
      volumeInMl = volume * 3785.41;
      break;
    default:
      throw new Error(`Unknown fromUnit: ${fromUnit}`);
  }

  // Convert from milliliters to the target unit
  switch (toUnit.toLowerCase()) {
    case "ml":
      return volumeInMl;
    case "liters":
      return volumeInMl / 1000;
    case "oz":
      return volumeInMl / 29.5735;
    case "gal":
      return volumeInMl / 3785.41;
    default:
      throw new Error(`Unknown toUnit: ${toUnit}`);
  }
};

// EnergyTransformer function takes energy and unit as input and returns the energy in the desired unit.
export const EnergyTransformer = (
  energy: number,
  fromUnit: string,
  toUnit: string,
): number => {
  // Convert the input energy to kilocalories first
  let energyInKcal: number;
  switch (fromUnit.toLowerCase()) {
    case "kcal":
      energyInKcal = energy;
      break;
    case "joules":
      energyInKcal = energy / 4184;
      break;
    case "btu":
      energyInKcal = energy / 3.96567;
      break;
    default:
      energyInKcal = energy; // Assume the input is already in kilocalories if unit is unknown
      break;
  }

  // Convert from kilocalories to the target unit
  switch (toUnit.toLowerCase()) {
    case "kcal":
      return energyInKcal;
    case "joules":
      return energyInKcal * 4184;
    case "btu":
      return energyInKcal * 3.96567;
    default:
      return energyInKcal; // Return the energy in kilocalories if target unit is unknown
  }
};
