import ErrorAlert from "@/components/Forms/Alert";
import FormWrapper from "@/components/Forms/FormCardWrapper";
import { ComboboxComponent } from "@/components/Forms/Inputs/ComboboxComponent";
import { InputComponent } from "@/components/Forms/Inputs/InputComponent";
import { InputError } from "@/components/Forms/Inputs/InputError";
import { InputLabelComponent } from "@/components/Forms/Inputs/InputLabel";
import { DatePicker } from "@/components/ui/DatePicker";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { CustomSkeleton } from "@/components/ui/skeleton";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { ToastAction } from "@/components/ui/toast";
import { useToast } from "@/components/ui/use-toast";
import { APIUrlBuilder } from "@/helper/functions/ApiUrlbuilder";
import { formattedDateForGo } from "@/helper/functions/date";
import { fetchData } from "@/helper/functions/fetch";
import {
  regularQueryOptions,
  staleQueryOptions,
} from "@/helper/lib/react-query";
import { useAuthStore } from "@/helper/store/authStore";
import type {
  Food,
  FoodWithModel,
  MealComponent,
  UserConsumedMeal,
} from "@/helper/types/FoodAndMeals";
import { useQuery } from "@tanstack/react-query";
import { Formik } from "formik";
import { type FC, useState } from "react";
import * as Yup from "yup";

const FoodValidationSchema = Yup.object().shape({
  newFood: Yup.string().required("Food name is required"),
  newFoodMeal: Yup.string().required("Meal name is required"),
  quantity: Yup.number().positive().integer().required("Quantity is required"),
});

const FoodForm = () => {
  const { toast } = useToast();
  const { UUID } = useAuthStore();

  const mealsEndpoint = APIUrlBuilder(`/meals/user/${UUID}`);
  const foodEndpoint = APIUrlBuilder("/foods");
  // TODO invalidate the cache when a new food is added
  const {
    data: foodList,
    isLoading: isFoodLoading,
    isError: isFoodError,
    error: foodError,
  } = useQuery({
    queryKey: ["foods"],
    queryFn: () => fetchData(foodEndpoint),
    select: (response) => {
      const foodList = response.foods;
      return convertDataToFoodOptions(foodList);
    },
    ...staleQueryOptions,
  });

  const {
    data: mealList,
    isLoading: isMealLoading,
    isError: isMealError,
    error: mealError,
  } = useQuery({
    queryKey: ["meals"],
    queryFn: () => fetchData(mealsEndpoint),
    select: (response) => {
      const mealsList = response.meals;
      return convertDataToMealOptions(mealsList);
    },
    ...regularQueryOptions,
  });

  if (isMealError || isFoodError) {
    return (
      <div>
        Error: {(mealError as Error).message || (foodError as Error).message}
      </div>
    );
  }

  return (
    <Formik
      initialValues={{
        newFood: "",
        newFoodMeal: "",
        quantity: 0,
      }}
      onSubmit={async (
        { newFood, newFoodMeal, quantity },
        { resetForm, setSubmitting },
      ) => {
        setSubmitting(true);
        const addFoodToMealEndpoint = APIUrlBuilder(
          `/meals/${newFoodMeal}/foods`,
        );
        // TODO bunu test et şuanda yemek veritabanı boş olduğu için çalışmaz.
        const payload: MealComponent[] = [
          {
            food_id: newFood,
            meal_id: newFoodMeal,
            quantity: Number.parseFloat(quantity.toString()),
          },
        ];
        console.log("Payload:", payload);

        try {
          const data = await fetchData(
            addFoodToMealEndpoint,

            "POST",
            payload,
          );
          console.log("Data:", data);

          if (data.success) {
            toast({
              title: "Added new food to your meal successfully!",
              description:
                "You have successfully added a new food to your meal.",
            });
            resetForm();
          } else {
            console.error("Form submission error:", data);
            toast({
              variant: "destructive",
              title: "Uh oh! Something went wrong.",
              description: `${data.message} Error:${data.data}`,
              action: <ToastAction altText="Try again">Try again</ToastAction>,
            });
          }
        } catch (error) {
          console.error("Network or server error:", error);
          toast({
            variant: "destructive",
            title: "Uh oh! Something went wrong.",
            description: `There was a problem with your request. Error:${error}`,
            action: <ToastAction altText="Try again">Try again</ToastAction>,
          });
        }
        setSubmitting(false);
      }}
      validationSchema={FoodValidationSchema}
    >
      {() => (
        <FormWrapper
          withCard
          cardDescription="Add a new food to your meal here. Click Add when you're done."
          cardTitle="Add new food"
        >
          <FoodCombobox
            Options={mealList ?? []}
            FieldName="newFoodMeal"
            LabelText="Yemek eklemek istediğiniz öğünü seçiniz"
            DefaultLabel="Öğünü seçiniz"
            EmptyText="Öğün bulunamadı."
            PlaceholderText="Öğün arayın..."
            isLoading={isMealLoading}
          />
          <div className="flex flex-col items-start gap-2 sm:flex-row sm:items-center">
            <FoodCombobox
              Options={foodList ?? []}
              FieldName="newFood"
              LabelText="Öğüne eklemek istediğiniz yemeği seçiniz"
              DefaultLabel="Yemek seçiniz"
              EmptyText="Yemek bulunamadı."
              PlaceholderText="Yemek arayın..."
              isLoading={isFoodLoading}
            />
            <div className="grid gap-2">
              <InputLabelComponent
                For="quantity"
                LabelText="Quantity"
                FieldName="quantity"
              />
              <InputComponent
                FieldId="quantity"
                Name="quantity"
                Placeholder="Quantity"
                InputType="number"
              />
              <InputError Name="quantity" />
            </div>
          </div>
        </FormWrapper>
      )}
    </Formik>
  );
};

export default FoodForm;

interface FoodComboboxProps {
  FieldName: string;
  Options: { value: string; label: string }[];
  DefaultLabel: string;
  EmptyText: string;
  PlaceholderText: string;
  LabelText: string;
  isLoading?: boolean;
}

const convertDataToMealOptions = (data: UserConsumedMeal[]) => {
  return data?.map((item) => {
    return { value: item.meal_id, label: item.meal_name };
  });
};

const convertDataToFoodOptions = (data: Food[]) => {
  return data?.map((item) => {
    return { value: item.food_id, label: item.name };
  });
};

export const FoodCombobox: FC<FoodComboboxProps> = ({
  DefaultLabel,
  EmptyText,
  FieldName,
  LabelText,
  Options,
  PlaceholderText,
  isLoading,
}) => {
  return (
    <div className="flex flex-col gap-2">
      <InputLabelComponent LabelText={LabelText} FieldName={FieldName} />
      <ComboboxComponent
        Options={Options}
        DefaultLabel={DefaultLabel}
        EmptyText={EmptyText}
        FieldName={FieldName}
        PlaceholderText={PlaceholderText}
        isLoading={isLoading}
      />
      <InputError Name={FieldName} />
    </div>
  );
};

// TODO finish the code below
export function ListAndEditUserFoods() {
  const { UUID } = useAuthStore();
  const [userDate, setUserDate] = useState(new Date(Date.now()));

  const endpoint = APIUrlBuilder(
    `/meals/user/${UUID}/${formattedDateForGo(userDate)}/foods`,
  );

  const {
    data: FoodList,
    isError,
    isLoading,
    error,
    // re-fetch,
  } = useQuery({
    queryFn: () => fetchData(endpoint),
    queryKey: ["MealfoodList", UUID, userDate],
    enabled: !!UUID,
    select: (data) => {
      return data.foods;
    },
    ...regularQueryOptions,
  });

  if (isError) {
    return <ErrorAlert errorMessage={(error as Error).message} />;
  }
  // TODO finish the code below, and add a dynamic grouping with meals from the user
  return (
    <Card>
      <div className="flex flex-col items-center justify-between md:flex-row">
        <CardHeader>
          <CardTitle>Meal</CardTitle>
          <CardDescription>
            List your custom meals and edit them here.
          </CardDescription>
        </CardHeader>
        <div className="md:mr-6">
          <DatePicker
            formValue={userDate}
            setValue={setUserDate as (value: Date | undefined) => void}
          />
        </div>
      </div>
      <CardContent>
        {isLoading ? (
          <div className="space-y-2">
            {[1, 2, 3].map((i) => (
              <CustomSkeleton key={i} className="h-4 w-full" />
            ))}
          </div>
        ) : (
          <Table className="text-center">
            <TableHeader>
              <TableRow>
                <TableHead className="text-center">ID</TableHead>
                <TableHead className="text-center">Food name</TableHead>
                <TableHead className="text-center">Food country</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {FoodList?.map(
                (food: FoodWithModel) =>
                  !food.deleted_at && (
                    <TableRow key={food.food_id}>
                      <TableCell>{food.food_id}</TableCell>
                      <TableCell>{food.name}</TableCell>
                      <TableCell>{food.country}</TableCell>
                      <TableCell>
                        {/* {new Date(food?.Date).toDateString()} */}
                      </TableCell>
                      <TableCell>
                        {/* <OptionDropdown
                          DefaultValue={food.CustomMealName}
                          FieldName="meal"
                          FieldLabel="Meal name"
                          Description="Edit the meal name here. Click Save changes when you're done."
                          Title="Edit Meal"
                          Endpoint={APIUrlBuilder(`/meals/${food.MealID}`)}
                          OnSuccess={refetch}
                          ValidationSchema={FoodValidationSchema}
                          Placeholder="Meal name"
                        /> */}
                      </TableCell>
                    </TableRow>
                  ),
              )}
            </TableBody>
          </Table>
        )}
      </CardContent>
    </Card>
  );
}
