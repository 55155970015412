import { UsernameSchema } from "@/helper/Schemas/username";
import * as Yup from "yup";
import { activityLevels, goals } from "../static/Onboarding/arrays";

const activity = activityLevels.map((activityLevel) => {
  return activityLevel.value;
});

const reOrganizedGoalsArray = goals.map((goal) => {
  return goal.value;
});

export const PersonalInfoValidationSchema = (
  usernameError: string,
  setReadyToCallAPI: (value: boolean) => void,
  setUsernameError: (value: string) => void,
) =>
  Yup.object().shape({
    User: Yup.object().shape({
      username: UsernameSchema(
        usernameError,
        setReadyToCallAPI,
        setUsernameError,
      ),
      firstName: Yup.string()
        .min(2, "Minimum 2 karakter gereklidir")
        .required("Ad alanı zorunludur"),
      lastName: Yup.string()
        .min(2, "Minimum 2 karakter gereklidir")
        .required("Soyad alanı zorunludur"),
    }),
    UserHealthProfiles: Yup.object().shape({
      birthDate: Yup.date()
        .required("Doğum tarihi zorunludur") // Doğum tarihi alanı için zorunluluk ekleniyor.
        .max(new Date(), "Gelecek tarihler geçerli değil"), // Doğum tarihinin bugün veya daha eski bir tarih olması gerektiğini belirtir.
      gender: Yup.string().required("Cinsiyet alanı zorunludur"),
      height: Yup.number()
        .required("Boy alanı zorunludur")
        // .max(380, "Boy en fazla 380 olabilir")
        .positive("Boy pozitif olmalıdır"),
      weight: Yup.number()
        .required("Kilo alanı zorunludur")
        // .max(800, "Kilo en fazla 800 olabilir")
        .positive("Kilo pozitif olmalıdır"),
    }),
  });

export const HealthSectionValidationSchema = Yup.object().shape({
  UserHealthProfiles: Yup.object().shape({
    smokingStatus: Yup.boolean().required("Sigara içme alanı zorunludur"),
    alcoholConsumption: Yup.string().required("Alkol içme alanı zorunludur"),
    existingConditions: Yup.array(),
  }),
  UserAllergy: Yup.object().shape({
    allergies: Yup.array(), //buna gene sonra bak
  }),
});

export const GoalsAndActivityValidationSchema = Yup.object().shape({
  UserHealthProfiles: Yup.object().shape({
    activityLevel: Yup.string()
      .required("Aktivite seviyesi seçimi zorunludur")
      .oneOf(activity, "Geçersiz aktivite seviyesi"),
    healthGoal: Yup.string()
      .required("Hedef seçimi zorunludur")
      .oneOf(
        [
          "weight_loss",
          "muscle_gain",
          "low_carb",
          "keto",
          "high_carb",
          "balanced",
          "heart_health",
          "immune_boost",
          "bone_health",
        ],
        "Geçersiz hedef",
      ),
  }),
});

export const SettingsSectionValidationSchema = Yup.object().shape({
  UserSettings: Yup.object().shape({
    preferred_meal_country: Yup.string().required("Ülke seçimi zorunludur"),
    theme: Yup.string().required("Tema seçimi zorunludur"),
    language: Yup.string().required("Dil seçimi zorunludur"),
    weight_unit: Yup.string().required("Kilo birimi seçimi zorunludur"),
    height_unit: Yup.string().required("Boy birimi seçimi zorunludur"),
    water_unit: Yup.string().required("Su birimi seçimi zorunludur"),
    energy_unit: Yup.string().required("Enerji birimi seçimi zorunludur"),
  }),
});

export const OnboardingSchema = Yup.object().shape({
  User: Yup.object().shape({
    username: Yup.string()
      .min(5, "Username is too short - should be 4 chars minimum.")
      .max(15, "Username is too long - should be 15 chars maximum.")
      .matches(
        /^[a-zA-Z0-9_]+$/,
        "Username can only contain alphanumeric characters and underscores.",
      )
      .required("Username is required"),
    firstName: Yup.string()
      .min(2, "Minimum 2 karakter gereklidir")
      .required("Ad alanı zorunludur"),
    lastName: Yup.string()
      .min(2, "Minimum 2 karakter gereklidir")
      .required("Soyad alanı zorunludur"),
  }),
  UserHealthProfiles: Yup.object().shape({
    birthDate: Yup.date()
      .required("Doğum tarihi zorunludur") // Doğum tarihi alanı için zorunluluk ekleniyor.
      .max(new Date(), "Gelecek tarihler geçerli değil"), // Doğum tarihinin bugün veya daha eski bir tarih olması gerektiğini belirtir.
    gender: Yup.string().required("Cinsiyet alanı zorunludur"),
    height: Yup.number()
      .required("Boy alanı zorunludur")
      .max(380, "Boy en fazla 380 olabilir")
      .positive("Boy pozitif olmalıdır"),
    weight: Yup.number()
      .required("Kilo alanı zorunludur")
      .max(800, "Kilo en fazla 800 olabilir")
      .positive("Kilo pozitif olmalıdır"),
    activityLevel: Yup.string()
      .required("Aktivite seviyesi seçimi zorunludur")
      .oneOf(activity, "Geçersiz aktivite seviyesi"),
    healthGoal: Yup.string()
      .required("Hedef seçimi zorunludur")
      .oneOf(reOrganizedGoalsArray, "Geçersiz hedef"),
    smokingStatus: Yup.boolean().required("Sigara içme alanı zorunludur"),
    alcoholConsumption: Yup.string().required("Alkol içme alanı zorunludur"),
    existingConditions: Yup.array(),
  }),
  UserAllergy: Yup.object().shape({
    allergies: Yup.array(), //buna gene sonra bak
  }),
  UserSettings: Yup.object().shape({
    theme: Yup.string().required("Tema seçimi zorunludur"),
    language: Yup.string().required("Dil seçimi zorunludur"),
    weight_unit: Yup.string().required("Kilo birimi seçimi zorunludur"),
    height_unit: Yup.string().required("Boy birimi seçimi zorunludur"),
    water_unit: Yup.string().required("Su birimi seçimi zorunludur"),
    energy_unit: Yup.string().required("Enerji birimi seçimi zorunludur"),
  }),
});
