import { useAuthStore } from "@/helper/store/authStore";
import { signInWithEmailAndPassword } from "firebase/auth";
import { APIUrlBuilder } from "../../ApiUrlbuilder";
import { fetchData } from "../../fetch";
import { auth } from "../firebase";

export const emailSignIn = async (email: string, password: string) => {
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password,
    );
    const user = userCredential.user;
    const token = await user.getIdToken(true); // Force token refresh

    // Send token to backend for validation and setting
    const endpoint = APIUrlBuilder("/auth/login");
    const response = await fetchData(endpoint, "POST", { token });

    const { userID, exists } = response;

    const { setIsRegistered, setUser, setRole, setSubscription, setUUID } =
      useAuthStore.getState();

    setUser(user);
    setUUID(userID);
    setIsRegistered(exists);

    // Set role and subscription from custom claims
    const idTokenResult = await user.getIdTokenResult(true);
    setRole(idTokenResult.claims.role as string);
    setSubscription(idTokenResult.claims.subscription as string);

    return exists;
  } catch (error) {
    console.error("Error signing in with email:", error);
    throw error;
  }
};
